import React from 'react'
import { setGlobalState, useGlobalState } from '../state';
import { JoinClanModal } from './Modals/JoinClanModal';
import { MessageModal } from './Modals/MessageModal';
export const JoinClanButton = (props) => {
  const [connectButtonStatus] = useGlobalState('connectButtonStatus')
  const joinClan = ()=>{
    document.querySelectorAll(".root-main").forEach(element => {
      element.style.display = "none";
  })
  if(connectButtonStatus !== "Connected"){
    setGlobalState('currentModal',[<MessageModal title="You are not connected!!!" message="Please connect your account."></MessageModal>]);
    setGlobalState('modalOpen',true);
  }else{
    setGlobalState('currentModal',[<JoinClanModal clanID={props.clanID}/>]);
    setGlobalState('modalOpen',true);
  }
  

  }
  return (
    <>
      <button className='listingbutton' onClick={joinClan}>Join</button>
    </>

  )
}
