import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCaF6gv6u-cX4Uz_0P_2-snfywtzDpFUEs",
  authDomain: "dcl1-333710.firebaseapp.com",
  projectId: "dcl1-333710",
  storageBucket: "dcl1-333710.appspot.com",
  messagingSenderId: "266647667415",
  appId: "1:266647667415:web:fcc4b0b3eec4ae641f6692"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);