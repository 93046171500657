import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { setGlobalState, useGlobalState } from '../../state'
import { ManageDropdown } from '../ManageDropdown'
import { MenuItems } from '../MenuItems'
import './Clans.css'

export const ManageClan = () => {
  const [refresh] = useGlobalState('refresh')
  const [userDetails] = useGlobalState('userDetails')
  const [ownedClans, setOwnedClans] = useState([])
  const [ownedClanData, setOwnedClanData] = useState([])
  const [dropdown, setDropdown] = useState("");

  const onMouseEnter = (identifier) => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown(identifier);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown("");
    }
  };


  useEffect(()=>{
    setGlobalState('useConnect',true)
  },[])

  useEffect(()=>{
    if(userDetails.length>0){
      fetch("https://www.wildernessp2e.com:5001/clans?requestType=ownedclan&userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => setOwnedClanData(result))
      .catch((error) => console.log(error));
    }
  },[userDetails, refresh])

  useEffect(()=>{
    const content = []
    for(let i=0; i <ownedClanData.length; i++){
      content.push(
        <div key={ownedClanData[i].tokenid} className='listing'>
          <div>
            <h2>Clan ID: 1_{ownedClanData[i].tokenid}</h2>
            <h2>Owner: {ownedClanData[i].ownername}</h2>
            <h2>Clan Size: {ownedClanData[i].members.length}</h2>
            <h2>Commission: {ownedClanData[i].commission}%</h2>
            <h2>Clan Type: {ownedClanData[i].type}</h2>
            <li
            className='dropdown-item'
            onMouseEnter={()=> onMouseEnter(ownedClanData[i].tokenid)}
            onMouseLeave={onMouseLeave}
          >
            <button
              className='dropdown-links'
            >
              Actions <i className='fas fa-caret-down' />
            </button>
            {dropdown===ownedClanData[i].tokenid && <ManageDropdown clanID={ownedClanData[i].tokenid} clanData={ownedClanData[i]} menuitems={MenuItems.manageclan}/>}
          </li>
          </div>
        </div>
      )
    }
    setOwnedClans(content);
  },[ownedClanData, dropdown])

  return (
    <>
      <div className="listingheader">Manage your clan</div>
      <div className='loading-overlay'>
        <LoadingOverlay active={userDetails.length >0? false:true} spinner text='Please Connect your Account'>
          <div className='listingcontainer'>
            {ownedClans}
          </div>
        </LoadingOverlay>
      </div>
    </>
  )
}
