import React, { useEffect } from 'react'
import LoadingOverlay from 'react-loading-overlay';
import { setGlobalState, useGlobalState } from '../state';
import metaUtils  from './../utils/connect';
import './Button.css';
import logo from './../images/logos/logo.webp';
export const ConnectButton = () => {
    const [buttonStatus, setButtonStatus] = useGlobalState('connectButtonStatus');
    const [userDetails] = useGlobalState('userDetails');
    const connect = async ()=>{
        if(buttonStatus !== "Connected"){
            console.log("triggered")
            setButtonStatus("Connecting")
            if(await metaUtils.handleConnect(setButtonStatus)){
                setGlobalState('userDetails',metaUtils.getAccountDetails())
                document.querySelectorAll(".connect-button").forEach(element => {
                element.style.cursor = "default";
                element.style.background = "rgba(8,162,0,1)";
                setButtonStatus("Connected")
            })
            }else{
                setButtonStatus("Connect")
            }
        }
    }

    // const resetAccount = ()=>{
    //     setGlobalState('userDetails',[])
    //     setButtonStatus("Connect")
    //     document.querySelectorAll(".connect-button").forEach(element => {
    //         element.style.cursor = "pointer";
    //         element.style.background = "linear-gradient(90deg, rgba(73,4,87,1) 0%, rgba(196,0,255,1) 100%);";
    //    })
    // }
    // const  handleChainSwitch = async ()=>{
    //     if(await metaUtils.checkChain() !== "0x89"){
    //         resetAccount()
    //     }
    // }
    
    // const  handleAccountSwitch = async ()=>{
    //     resetAccount()
    // }

    useEffect(()=>{
        if(buttonStatus === "Connected"){
            document.querySelectorAll(".connect-button").forEach(element => {
                element.style.cursor = "default";
                element.style.background = "rgba(8,112,0,1)";
            })
            
        }else{
            document.querySelectorAll(".connect-button").forEach(element => {
                element.style.cursor = "pointer";
                element.style.background = "linear-gradient(90deg, rgba(73,4,87,1) 0%, rgba(196,0,255,1) 100%)";
           })
        }
    },[buttonStatus])

    // window.ethereum.on('accountsChanged', handleAccountSwitch);
    // window.ethereum.on('chainChanged', handleChainSwitch);

  return (
    <>
        
        <div className='connect-button-container'>
            {userDetails.length>0 && 
                <div className='user-balance'>
                    <img src={logo} alt='WLDY LOGO'></img>
                    <label>{ userDetails[2] }</label>
                </div>
            }
            <LoadingOverlay className='connect-loading' active={buttonStatus==="Connect" || buttonStatus==="Connected"? false:true} spinner>
                <button className='connect-button' onClick={connect}>{buttonStatus}</button>
            </LoadingOverlay>
        </div>
    </>
  )
}
