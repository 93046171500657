import React from 'react'
import { setGlobalState } from '../../state';

export const MessageModal = (props) => {
  return (
    <>
    <div className="modalContainer">
    <div className="titleCloseBtn">
      <button
        onClick={() => {
          setGlobalState('modalOpen',false);
          if(props.callback){
            props.callback()
          }
        }}
      >
        X
      </button>
    </div>
    <div className="title">
        <h1>{props.title}</h1>
    </div>
    <div className="body">
        <p>{props.message}</p>
    </div>
  </div>
</>
  )
}
