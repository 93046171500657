import React, { useEffect, useState } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { setGlobalState, useGlobalState } from '../state';
import metaUtils  from '../utils/connect';
import { BuyModal } from './Modals/BuyModal';
import { MessageModal } from './Modals/MessageModal';
const ItemSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const [userDetails] = useGlobalState('userDetails')
  let [alternator,setAlternator] = useState(false);
  useEffect(() => {
    setTimeout(
      () =>{
        nextSlide();
        if(alternator){
            setAlternator(false)
        }else{
            setAlternator(true);
        }
      },
      10000
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternator]);


  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  const buyItem = async (itemDetails)=> {
    console.log(itemDetails)
    if(userDetails.length>0){
      if(parseInt(userDetails[2]) >  parseInt(metaUtils.convertFromWei(itemDetails.price))){
        document.querySelectorAll(".root-main").forEach(element => {
          element.style.display = "none";
        })
        setGlobalState('currentModal',<BuyModal itemDetails={itemDetails}></BuyModal>)
        setGlobalState('modalOpen',true)
      }else{
        document.querySelectorAll(".root-main").forEach(element => {
          element.style.display = "none";
        })
        setGlobalState('currentModal',<MessageModal title={"Aleart"} message={"Not enough funds"}></MessageModal>)
        setGlobalState('modalOpen',true)
      }

    }else{
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
      })
      setGlobalState('currentModal',<MessageModal title={"Aleart"} message={"Please connect your wallet before buying"}></MessageModal>)
      setGlobalState('modalOpen',true)
    }
  }
  return (
    <section className='item-slider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active slideritem' : 'slide'}
            key={index}
          >
            
            {index === current && (
              <div className='slider-item item'>
                <img src={slide.image} alt=''></img>
                <p class="itemtitle">{slide.name}</p>
                <div className='item-details'>
                  <p class="itemcategory">{" " + slide.category.replace('_'," ").toUpperCase()}</p>
                  <p className={slide.rarity+" raritytext"}>{" " + slide.rarity.toUpperCase()}</p>
                  <p class="pricetext">{ addCommas("" + metaUtils.convertFromWei(slide.price))} $WDLY</p>
                  { slide.item_left !== "0" && <p class="remainingtext">{ getremainingstring(slide)}</p>}
                </div>
                {slide.item_left>0?<button onClick={()=> {buyItem(slide)}}>Buy</button>:<button className='outofstock'>Out of stock</button>}
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ItemSlider;

function getremainingstring(obj){

  let r = obj.rarity
  let supply = 0
  if(r === "mythic"){
    supply = 10
  } else if(r === "legendary"){
    supply = 100
  } else if(r === "epic"){
    supply = 1000
  } else if(r === "rare"){
    supply = 5000
  } else if(r === "uncommon"){
    supply = 10000
  } else if(r === "common"){
    supply = 100000
  }
  if(obj.item_left < 0.25*supply){
    return  "Only " + obj.item_left+" remaining!"
  }
  return  "" + obj.item_left+"/"+supply+" in stock"

}
function addCommas(str) {
  var parts = (str + "").split("."),
      main = parts[0],
      len = main.length,
      output = "",
      first = main.charAt(0),
      i;

  if (first === '-') {
      main = main.slice(1);
      len = main.length;    
  } else {
      first = "";
  }
  i = len - 1;
  while(i >= 0) {
      output = main.charAt(i) + output;
      if ((len - i) % 3 === 0 && i > 0) {
          output = "," + output;
      }
      --i;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
      output += "." + parts[1];
  }
  return output;
}