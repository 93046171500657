import React from 'react'
import { setGlobalState } from '../../state'
import { LeaveClanModal } from '../Modals/LeaveClanModal'

export const MyClan = (props) => {
  const leaveClan = ()=>{
    console.log("here")
    document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
    })
    setGlobalState('currentModal',<LeaveClanModal clanID={props.myClanData[0].tokenid}></LeaveClanModal>)
    setGlobalState('modalOpen',true)
  }
  return (
    <>
    <div className='myclan-header'>
      <h2>My Clan</h2>
    </div>
    <div className='myclan'>    
        <div className='mylisting'>
                {props.myClanData.length>0? <div>
                    <h2>Clan ID: 1_{props.myClanData[0].tokenid}</h2>
                    <h2>Owner: {props.myClanData[0].ownername}</h2>
                    <h2>Clan Size: {props.myClanData[0].members.length}</h2>
                    <h2>Commission: {props.myClanData[0].commission}%</h2>
                    <button className='genric-button' onClick={leaveClan}>Leave Clan</button>
                    </div>
                    :
                    <>
                    <div>
                        Not in any clan
                    </div>
                    </>                    
                    }
                
        </div>
    </div>
    </>
    
  )
}
