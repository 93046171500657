import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { setGlobalState, useGlobalState } from '../../state';
import metaUtils  from '../../utils/connect';
import './KickModal.css'
import { MessageModal } from './MessageModal';
export const KickModal = (props) => {
  const [content, setContent] = useState([])
  const [userDetails] = useGlobalState('userDetails')
  const [loadingtext, setLoadingText] = useState("")
  const [result, setResult] = useState({'status':"none"})

  useEffect(()=>{
    const verify = async ()=>{return new Promise(async (resolve, reject)=>{
        await fetch("https://www.wildernessp2e.com:5001/getnonce?userid=" + userDetails[0])
        .then((response) => response.json())
        .then(async (result) => {
            console.log(result)
            if(result.status==="success"){
                let signedMsg = "Requesting to kick Clan member?clanid="+props.clanData.tokenid +"?userid="+userDetails[0]+"?nonce=" + result.nonce
                console.log(signedMsg)
    
                try{
                    signedMsg = await metaUtils.personalSign(signedMsg, userDetails[0])
                    resolve(signedMsg)
                }catch(err){
                    setResult({"status":"failed", "title":"Fail message","message":err.message})
                    reject()
                }
            }
        })
    })    
    }
    const kick = async (memberid, clanid)=>{ 
        
        console.log(props.clanData.members)
        
        setLoadingText("Verifying")
        await verify().then(async (result) => {
            console.log(result)
            setLoadingText("Requesting")
            await fetch("https://www.wildernessp2e.com:5001/clans?requestType=kickmember&clanid="+props.clanData.tokenid+"&userid=" + userDetails[0]+ "&memberid=" + memberid + "&signature=" +result)
            .then((response) => response.json())
            .then(async (result) => {
                if(result.status === "success"){
                    document.querySelectorAll('.id'+memberid).forEach(element => {
                        element.remove()
                    })
                    setLoadingText("")
                }else{
                    setResult({"status":"failed", "title":"Fail message","message":"Failed to kick clan member"})
                }

            })
        })

      }

    const kickContent = []
    for(let i= 0; i< props.clanData.members.length; i++){
        const memberid = props.clanData.members[i];
        kickContent.push(
            <div className={'kick-item id' + memberid}>
                <p>{memberid.slice(0,6)+ "...." + memberid.slice(-4)}</p>
                <button onClick={()=>kick(memberid, props.clanData.tokenid)}>Kick</button>   
            </div>
        )
    }
    setContent(kickContent)
  },[props, userDetails])
  
  return (
    <>
    {result.status === "none"?
        <LoadingOverlay active={loadingtext.length===0? false:true} spinner text={loadingtext}>
            <div className="kick-modalContainer">
                <div className="titleCloseBtn">
                <button
                    onClick={() => {
                    setGlobalState('modalOpen',false);
                    }}
                >
                    X
                </button>
                </div>
                <div className="title">
                <h1>Clan Members</h1>
                </div>
                <div className="body">
                {content}
                </div>
            </div>
        </LoadingOverlay>
        :
        <MessageModal title={result.title} message={result.message}></MessageModal>
        }
    </>
  )
}
