import React from 'react';
import '../../App.css';
import './Primarymarket.css';
// import { abi } from '../../utils/abi';
// import Web3 from 'web3';
// import { nftContracts } from '../../utils/nftContracts';
import { setGlobalState, useGlobalState } from './../../state';
import { useEffect } from 'react';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import graph_util from '../../utils/graph';
import { BuyModal } from '../Modals/BuyModal';
import { MessageModal } from '../Modals/MessageModal';
import  metaUtils  from '../../utils/connect';
import { MarketPlaceSliderData } from '../SliderData';
import MarketSlider from '../MarketSlider';
import ItemSlider from '../ItemSlider';
export default function Primarymarket() {
  const [userDetails] = useGlobalState('userDetails')
  const [items, setItems] = useState([])
  const [vipItems, setVIPItems] = useState([])
  const [itemsResult, setItemsResult] = useState([])
  //let web3 = new Web3(window.ethereum);
  
  useEffect(() => {
    const buyItem = async (itemDetails, isVIPSale)=> {
      console.log(itemDetails)
      if(userDetails.length>0){
        if(parseInt(userDetails[2]) >  parseInt(metaUtils.convertFromWei(itemDetails.price))){
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<BuyModal itemDetails={itemDetails} vipSale = {isVIPSale}></BuyModal>)
          setGlobalState('modalOpen',true)
        }else{
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<MessageModal title={"Aleart"} message={"Not enough funds"}></MessageModal>)
          setGlobalState('modalOpen',true)
        }

      }else{
        document.querySelectorAll(".root-main").forEach(element => {
          element.style.display = "none";
        })
        setGlobalState('currentModal',<MessageModal title={"Aleart"} message={"Please connect your wallet before buying"}></MessageModal>)
        setGlobalState('modalOpen',true)
      }
    }
    const itemContent = []
    const vipItemContent = []
    graph_util.getVIPSaleListing().then((result)=>{
      console.log(result.vipitems)
      for(let i=0;i<result.vipitems.length;i++){
        console.log(result.vipitems[i].rarity)
        vipItemContent.push(<>
          <div className='item'>
            <img src={result.vipitems[i].image} alt=''></img>
            <p class="itemtitle">{result.vipitems[i].name}</p>
            <div className='item-details'>
              <p class="itemcategory">{" " + result.vipitems[i].category.replace('_'," ").toUpperCase()}</p>
              <p className={result.vipitems[i].rarity+" raritytext"}>{" " + result.vipitems[i].rarity.toUpperCase()}</p>
              <p class="pricetext">{ addCommas("" + metaUtils.convertFromWei(result.vipitems[i].price))} $WDLY</p>
              {result.vipitems[i].item_left !== "0" && <p class="remainingtext">{getremainingstring(result.vipitems[i])}</p>}
            </div>

            {result.vipitems[i].item_left>0?<button onClick={()=> {buyItem(result.vipitems[i], true)}}>BUY</button>:<button className='outofstock'>Out of stock</button>}
          </div>
        </>)
      }
      setVIPItems(vipItemContent)
    })
    .catch((error) => console.log(error));
    graph_util.getPrimaryMarketListing().then((result)=>{
      console.log(result.items)
      setItemsResult(result.items)
      for(let i=0;i<result.items.length;i++){
        console.log(result.items[i].rarity)
        itemContent.push(<>
          <div className='item'>
            <img src={result.items[i].image} alt=''></img>
            <p class="itemtitle">{result.items[i].name}</p>
            <div className='item-details'>
              <p class="itemcategory">{" " + result.items[i].category.replace('_'," ").toUpperCase()}</p>
              <p className={result.items[i].rarity+" raritytext"}>{" " + result.items[i].rarity.toUpperCase()}</p>
              <p class="pricetext">{ addCommas("" + metaUtils.convertFromWei(result.items[i].price))} $WDLY</p>
              { result.items[i].item_left !== "0" && <p class="remainingtext">{getremainingstring(result.items[i])}</p>}
            </div>

            {result.items[i].item_left>0?<button onClick={()=> {buyItem(result.items[i], false)}}>BUY</button>:<button className='outofstock'>Out of stock</button>}
          </div>
        </>)
      }
      setItems(itemContent)
    })
    .catch((error) => console.log(error));

    
    setGlobalState("useConnect", true);
  },[userDetails]);

  return <>
    <MarketSlider slides={MarketPlaceSliderData} />
    <ItemSlider slides={itemsResult}></ItemSlider>
    {vipItems.length >0 && <>
      <div className="highscore-title">VIP Sales</div>
      <LoadingOverlay active={vipItems.length >0? false:true} spinner text="Loading Items">
        <div className='marketplace-container'>
          {vipItems}
        </div>
      </LoadingOverlay>
    </>}

    <div className="highscore-title">Primaray Marketplace</div>
    <LoadingOverlay active={items.length >0? false:true} spinner text="Loading Items">
      <div className='marketplace-container'>
        {items}
      </div>
    </LoadingOverlay>
  </>
}
function addCommas(str) {
  var parts = (str + "").split("."),
      main = parts[0],
      len = main.length,
      output = "",
      first = main.charAt(0),
      i;

  if (first === '-') {
      main = main.slice(1);
      len = main.length;    
  } else {
      first = "";
  }
  i = len - 1;
  while(i >= 0) {
      output = main.charAt(i) + output;
      if ((len - i) % 3 === 0 && i > 0) {
          output = "," + output;
      }
      --i;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
      output += "." + parts[1];
  }
  return output;
}
function getremainingstring(obj){

  let r = obj.rarity
  let supply = 0
  if(r === "mythic"){
    supply = 10
  } else if(r === "legendary"){
    supply = 100
  } else if(r === "epic"){
    supply = 1000
  } else if(r === "rare"){
    supply = 5000
  } else if(r === "uncommon"){
    supply = 10000
  } else if(r === "common"){
    supply = 100000
  }
  if(obj.item_left < 0.25*supply){
    return  "Only " + obj.item_left+" remaining!"
  }
  return  "" + obj.item_left+"/"+supply+" in stock"

}