/* eslint-disable no-undef */
const { ethers } = require('ethers');
var Web3 = require('web3');
const { ERC721CollectionV2 } = require('../abis/ERC721CollectionV2');
const { WLDYABI } = require('../abis/WLDYCoins');
var net1 = require('net');
const { WLDYSecondarymarket } = require('../abis/WLDYSecondarymarket');
class MetamaskUtils {
    constructor(){
        const { ethereum } = window;
        Boolean(ethereum && ethereum.isMetaMask)
        if(Boolean(ethereum && ethereum.isMetaMask)){
            this.ethereum = ethereum;
            this.web3 = new Web3(this.ethereum);
            this.web3_polygon = new Web3('https://polygon-rpc.com/', net1)
        }else{
            this.web3 = new Web3('https://polygon-rpc.com/', net1)
        }
    }
    isMetaMaskInstalled() {
        const { ethereum } = window;
        if(Boolean(ethereum && ethereum.isMetaMask)){
            return true;
        }else{
            var install = window.confirm("Metamask is not installed Please click ok to install");
            if (install) {
                window.open(
                    'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                    '_blank' // <- This is what makes it open in a new window.
                  );
            }
            return false;
        }
    };

    async handleConnect(setButtonStatus){
        if(this.isMetaMaskInstalled()){
            setButtonStatus("Connecting account");
            if(await this.connect()){
                return true;
            }
            // if(await this.checkChain() === "0x89"){
            //     if(await this.connect()){
            //         return true;
            //     } 
            // }else{
            //     setButtonStatus("Switching to Polygon")
            //     if(await this.switchPolygon()){
            //         setButtonStatus("Connecting account");
            //         if(await this.connect()){
            //             return true;
            //         }
            //     }else{
            //         setButtonStatus("Adding polygon chain");
            //         if(await this.addPolygonChain()){
            //             if(await this.checkChain() === '0x89'){
            //                 setButtonStatus("Connecting account");
            //                 if(await this.connect()){
            //                     return true;
            //                 }
            //             }else{
            //                 setButtonStatus("Switching to Polygon");
            //                 if(await this.switchPolygon()){
            //                     setButtonStatus("Connecting account");
            //                     if(await this.connect()){
            //                         return true;
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }
        }
        return false;
    }


    getAccountDetails(){
        return this.accountDetails;
    }
    async connect(){
        try {
            const account = await this.ethereum.request({ method: 'eth_requestAccounts' });
            console.log(account)
            if(account.length>0){
                const coinContractAddress = '0x258d8423d9d6fae7bff3e5b7d1aa13348492aa0b';
                const coinContract = new this.web3_polygon.eth.Contract(WLDYABI,coinContractAddress);
                const balance = Math.floor(this.convertFromWei(await coinContract.methods.balanceOf(account[0]).call())*10)/10;
                this.accountDetails = [account[0], "Polygon", balance];
                return true;
            }else{
                return false;
            }
            
          } catch (error) {
                return false;
          }
    }

    async checkChain() {
        try {
            return await this.ethereum.request({ method: 'eth_chainId' });
        }
        catch(error){
            console.error(error);
        }
        
    }

    async addPolygonChain(){
        try{
            await this.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                    chainId: `0x${Number(137).toString(16)}`,
                    chainName: "Polygon Mainnet",
                    nativeCurrency: {
                        name: "MATIC",
                        symbol: "MATIC",
                        decimals: 18
                    },
                    rpcUrls: ["https://polygon-rpc.com/"],
                    blockExplorerUrls: ["https://polygonscan.com/"]
                    }
                ]
            })
            return true; 
        }catch(error){
            return false
        }
    }

    async switchPolygon(){
        try{
            await this.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [
                 {
                  chainId: "0x89"
                 }
                ]
            });
            return true; 
        }
        catch(error){
            return false;
        }
    }

    async personalSign(message, address){
        return await this.web3.eth.personal.sign(message, address)
    }

    convertToWei(value){
        return this.web3.utils.toWei(value)
    }

    convertFromWei(value){
        return this.web3.utils.fromWei(value)
    }

     to32Bytes(value) {
        return value
          .toString()
          .replace('0x', '')
          .padStart(64, '0')
      }
    getSalt(chainId) {
        if (typeof chainId === 'number') {
          return `0x${this.to32Bytes(chainId.toString(16))}`
        }
        
        return `0x${this.to32Bytes(chainId)}`
    }

    async metaApproveNFT(nft, tokenID){
        console.log("nft address" + nft)
        console.log("token id" + tokenID)
        console.log("from    " + this.accountDetails[0])
        // console.log("to    " + "0xd65a2b2266F300a734ADA120d7cF580DBE73b5b6")
        const nftContract = new this.web3_polygon.eth.Contract(ERC721CollectionV2,nft)
        const verifyingContractVersion = '2';
        const verifyingContractChainId = this.getSalt(137);
        console.log("chain id     " + verifyingContractChainId)
        const verifyingContractName = 'Decentraland Collection';
        console.log(tokenID)
        console.log(BigInt(tokenID))
        console.log(BigInt(parseInt(tokenID)))
        console.log(parseInt(tokenID))
        const txn_proto = nftContract.methods.approve("0xd2d00E4ADCB0eC7bd185b5217574Bd172d1CB513", BigInt(tokenID));

        let nonce = 0
        try {
            nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call();
        } catch {}
        // const nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call()
    
        console.log("encodedABI")
        console.log(txn_proto.encodeABI())
        console.log("nonce")
        console.log(nonce)

        const msgParams = JSON.stringify({
        domain: {
            name: verifyingContractName,
            version: verifyingContractVersion,
            verifyingContract: nft,
            salt: verifyingContractChainId
        },
    
        // Defining the message signing data content.
        message: {
            /*
            - Anything you want. Just a JSON Blob that encodes the data you want to send
            - No required fields
            - This is DApp Specific
            - Be as explicit as possible when building out the message schema.
            */
            nonce: nonce,
            from: this.accountDetails[0],
            functionSignature: txn_proto.encodeABI()
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'MetaTransaction',
        types: {
            // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
            EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'verifyingContract', type: 'address' },
            { name: 'salt', type:'bytes32'}
            ],
            // Refer to PrimaryType
            MetaTransaction: [
            { name: 'nonce', type: 'uint256' },
            { name: 'from', type: 'address' },
            { name: 'functionSignature', type: 'bytes' }
            ]
        },
    })
        try{
            let signature = await web3.currentProvider.request({ method: 'eth_signTypedData_v4', params: [this.accountDetails[0], msgParams]});
            var sig = ethers.utils.splitSignature(signature);
            console.log(sig.r)
            console.log(sig.s)
            console.log(sig.v)

            return {"status":"success", "signature":[sig.r,sig.s,sig.v]}
        }catch(err){
            console.log(err)
            return {"status":"failed", "title":"Failed", "message":err.message}
        }
    }

    async metaApproveWLDY(value){
        const coinContractAddress = '0x258d8423d9d6fae7bff3e5b7d1aa13348492aa0b';
        const coinContract = new this.web3_polygon.eth.Contract(WLDYABI,coinContractAddress);
        const verifyingContractVersion = '1';
        const verifyingContractChainId = 137;
        const verifyingContractName = 'WLDYCoin';
        const txn_proto = coinContract.methods.approve("0xd2d00E4ADCB0eC7bd185b5217574Bd172d1CB513", value);
        console.log('tt')
        let nonce = 0
        try {
            nonce = await coinContract.methods.getNonce(this.accountDetails[0]).call();
        } catch {}
        console.log("encodedABI")
        console.log(txn_proto.encodeABI())
        console.log("nonce")
        console.log(nonce)
        console.log(value)
        const msgParams = JSON.stringify({
            domain: {
                name: verifyingContractName,
                version: verifyingContractVersion,
                verifyingContract: coinContractAddress,
                salt: verifyingContractChainId
            },
        
            // Defining the message signing data content.
            message: {
                /*
                - Anything you want. Just a JSON Blob that encodes the data you want to send
                - No required fields
                - This is DApp Specific
                - Be as explicit as possible when building out the message schema.
                */
                nonce: nonce,
                from: this.accountDetails[0],
                functionSignature: txn_proto.encodeABI()
            },
            // Refers to the keys of the *types* object below.
            primaryType: 'MetaTransaction',
            types: {
                // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'verifyingContract', type: 'address' },
                { name:'salt', type:'uint256' }
                ],
                // Refer to PrimaryType
                MetaTransaction: [
                { name: 'nonce', type: 'uint256' },
                { name: 'from', type: 'address' },
                { name: 'functionSignature', type: 'bytes' }
                ]
            },
        })
        try{
            let signature = await web3.currentProvider.request({ method: 'eth_signTypedData_v4', params: [this.accountDetails[0], msgParams]});
            var sig = ethers.utils.splitSignature(signature);
            return {"status":"success", "signature":[sig.r,sig.s,sig.v]}
        }catch(err){
            console.log(err)
            return {"status":"failed", "title":"Failed", "message":err.message}
        }
    }


    async cancelOrder(nft, tokenID){
        console.log("nft address" + nft)
        console.log("checksum address" + this.web3_polygon.utils.toChecksumAddress(nft))
        console.log("token id" + tokenID)
        console.log("from    " + this.accountDetails[0])
        // console.log("to    " + "0xd65a2b2266F300a734ADA120d7cF580DBE73b5b6")
        const nftContract = new this.web3_polygon.eth.Contract(WLDYSecondarymarket,"0xd2d00E4ADCB0eC7bd185b5217574Bd172d1CB513")
        const verifyingContractVersion = '0.1';
        const verifyingContractChainId = this.getSalt(137);
        console.log("chain id     " + verifyingContractChainId)
        const verifyingContractName = 'Wilderness Marketplace';
        console.log(tokenID)
        console.log(BigInt(tokenID))
        console.log(BigInt(parseInt(tokenID)))
        console.log(parseInt(tokenID))
        const txn_proto = nftContract.methods.cancelOrder(nft, BigInt(tokenID))

        let nonce = 0
        try {
            nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call();
        } catch {}
        // const nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call()
    
        console.log("encodedABI")
        console.log(txn_proto.encodeABI())
        console.log("nonce")
        console.log(nonce)

        const msgParams = JSON.stringify({
        domain: {
            name: verifyingContractName,
            version: verifyingContractVersion,
            verifyingContract: "0xd2d00E4ADCB0eC7bd185b5217574Bd172d1CB513",
            salt: verifyingContractChainId
        },
    
        // Defining the message signing data content.
        message: {
            /*
            - Anything you want. Just a JSON Blob that encodes the data you want to send
            - No required fields
            - This is DApp Specific
            - Be as explicit as possible when building out the message schema.
            */
            nonce: nonce,
            from: this.accountDetails[0],
            functionSignature: txn_proto.encodeABI()
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'MetaTransaction',
        types: {
            // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
            EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'verifyingContract', type: 'address' },
            { name: 'salt', type:'bytes32'}
            ],
            // Refer to PrimaryType
            MetaTransaction: [
            { name: 'nonce', type: 'uint256' },
            { name: 'from', type: 'address' },
            { name: 'functionSignature', type: 'bytes' }
            ]
        },
    })
        try{
            let signature = await web3.currentProvider.request({ method: 'eth_signTypedData_v4', params: [this.accountDetails[0], msgParams]});
            var sig = ethers.utils.splitSignature(signature);
            console.log(sig.r)
            console.log(sig.s)
            console.log(sig.v)

            return {"status":"success", "signature":[sig.r,sig.s,sig.v], "fSig":txn_proto.encodeABI()}
        }catch(err){
            console.log(err)
            return {"status":"failed", "title":"Failed", "message":err.message}
        }
    }

    async metaBurnNFT(nft, tokenID){
        console.log("nft address" + nft)
        console.log("token id" + tokenID)
        console.log("from    " + this.accountDetails[0])
        // console.log("to    " + "0xd65a2b2266F300a734ADA120d7cF580DBE73b5b6")
        const nftContract = new this.web3_polygon.eth.Contract(ERC721CollectionV2,nft)
        const verifyingContractVersion = '2';
        const verifyingContractChainId = this.getSalt(137);
        console.log("chain id     " + verifyingContractChainId)
        const verifyingContractName = 'Decentraland Collection';
        console.log(tokenID)
        console.log(BigInt(tokenID))
        console.log(BigInt(parseInt(tokenID)))
        console.log(parseInt(tokenID))
        const txn_proto = nftContract.methods.transferFrom(this.accountDetails[0], "0xd65a2b2266F300a734ADA120d7cF580DBE73b5b6", BigInt(tokenID));

        let nonce = 0
        try {
            nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call();
        } catch {}
        // const nonce = await nftContract.methods.getNonce(this.accountDetails[0]).call()
    
        console.log("encodedABI")
        console.log(txn_proto.encodeABI())
        console.log("nonce")
        console.log(nonce)

        const msgParams = JSON.stringify({
        domain: {
            name: verifyingContractName,
            version: verifyingContractVersion,
            verifyingContract: nft,
            salt: verifyingContractChainId
        },
    
        // Defining the message signing data content.
        message: {
            /*
            - Anything you want. Just a JSON Blob that encodes the data you want to send
            - No required fields
            - This is DApp Specific
            - Be as explicit as possible when building out the message schema.
            */
            nonce: nonce,
            from: this.accountDetails[0],
            functionSignature: txn_proto.encodeABI()
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'MetaTransaction',
        types: {
            // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
            EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'verifyingContract', type: 'address' },
            { name: 'salt', type:'bytes32'}
            ],
            // Refer to PrimaryType
            MetaTransaction: [
            { name: 'nonce', type: 'uint256' },
            { name: 'from', type: 'address' },
            { name: 'functionSignature', type: 'bytes' }
            ]
        },
    })
        try{
            let signature = await web3.currentProvider.request({ method: 'eth_signTypedData_v4', params: [this.accountDetails[0], msgParams]});
            var sig = ethers.utils.splitSignature(signature);
            return {"status":"success", "signature":[sig.r,sig.s,sig.v]}
        }catch(err){
            console.log(err)
            return {"status":"failed", "title":"Failed", "message":err.message}
        }
    }

    async metaBurnWLDY(value){
        const coinContractAddress = '0x258d8423d9d6fae7bff3e5b7d1aa13348492aa0b';
        const coinContract = new this.web3_polygon.eth.Contract(WLDYABI,coinContractAddress);
        const verifyingContractVersion = '1';
        const verifyingContractChainId = 137;
        const verifyingContractName = 'WLDYCoin';
        const txn_proto = coinContract.methods.burn(value);
        console.log(value)
        console.log('tt')
        let nonce = 0
        try {
            nonce = await coinContract.methods.getNonce(this.accountDetails[0]).call();
        } catch {}
        console.log("encodedABI")
        console.log(txn_proto.encodeABI())
        console.log("nonce")
        console.log(nonce)
        console.log(value)
        const msgParams = JSON.stringify({
            domain: {
                name: verifyingContractName,
                version: verifyingContractVersion,
                verifyingContract: coinContractAddress,
                salt: verifyingContractChainId
            },
        
            // Defining the message signing data content.
            message: {
                /*
                - Anything you want. Just a JSON Blob that encodes the data you want to send
                - No required fields
                - This is DApp Specific
                - Be as explicit as possible when building out the message schema.
                */
                nonce: nonce,
                from: this.accountDetails[0],
                functionSignature: txn_proto.encodeABI()
            },
            // Refers to the keys of the *types* object below.
            primaryType: 'MetaTransaction',
            types: {
                // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'verifyingContract', type: 'address' },
                { name:'salt', type:'uint256' }
                ],
                // Refer to PrimaryType
                MetaTransaction: [
                { name: 'nonce', type: 'uint256' },
                { name: 'from', type: 'address' },
                { name: 'functionSignature', type: 'bytes' }
                ]
            },
        })
        try{
            let signature = await web3.currentProvider.request({ method: 'eth_signTypedData_v4', params: [this.accountDetails[0], msgParams]});
            var sig = ethers.utils.splitSignature(signature);
            console.log([sig.r,sig.s,sig.v])
            return {"status":"success", "signature":[sig.r,sig.s,sig.v]}
        }catch(err){
            console.log(err)
            return {"status":"failed", "title":"Failed", "message":err.message}
        }
    }

}

let metaUtils = new MetamaskUtils();

export default metaUtils;


