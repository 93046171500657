  export const SliderData = [
    {
        image:"pic2.webp",
        h1:"Play to Earn Crypto and NFTs",
        p:"Wilderness Wizards are rewarded with the $WLDY token which can be exchanged for new releases of NFTs or traded on Uniswap"
    },
    {
        image:"wearablles.webp",
        h1:"Collect Decentraland Wearables",
        p:"Wilderness NFTs are Decentraland wearables that provide in-game bonuses. Wear them across the metaverse and fight hard!"
    },
    {
        image:"pic1.webp",
        h1:"Yer a Wizard",
        p:"Join our family of Wizards and grind together, fight together and win together!"
    }
  ];

export const MarketPlaceSliderData = [
  {
    image:"mp1.webp"
  },    
  {
      image:"mp2.webp"
  },
  {
      image:"mp3.webp"
  }
];