import React from 'react'
import { useState } from 'react';
import { setGlobalState, useGlobalState } from '../../state';
import './DefaultModal.css'
import './CreateOrderModal.css'
import LoadingOverlay from 'react-loading-overlay';
import { MessageModal } from './MessageModal';
import metaUtils from '../../utils/connect';
export const ExecuteOrderModal = (props) => {
  const [result, setResult] = useState({'status':"none"});
  const [modalText, setModalText] = useState("");
  const [userDetails] = useGlobalState('userDetails');

  const handleExecuteOrder = async (nftDetails)=>{
    setModalText("Requesting your WLDY Token to be used on behalf of you");
    console.log(nftDetails)
    const wldySig = await metaUtils.metaApproveWLDY(nftDetails.price);
    if(wldySig.status === "success"){
        // fetch("http://localhost:5000/metatxn?requestType=metaexecuteorder"
        fetch("https://www.wildernessp2e.com:5001/metatxn?requestType=metaexecuteorder"
        + "&nft=" + nftDetails.nftAddress
        + "&tokenid=" + nftDetails.assetId
        + "&userAddress=" + userDetails[0]
        + "&price=" + nftDetails.price
        + "&coin_sigR=" + wldySig.signature[0]
        + "&coin_sigS=" + wldySig.signature[1]
        + "&coin_sigV=" + wldySig.signature[2]
        ).then((response) => response.json())
        .then((result)=>{
            console.log(result)
            if(result.status === "success"){
                setResult(result)
            }else{
                setResult(result)
            }
            
        })
    }else{
        setResult(wldySig)
    }
  }
  
  return (
    <>
      {result.status === "none" && 
        <LoadingOverlay active={modalText.length>0? true:false} spinner text={modalText}>
        <div className="modalContainer">
            <div className="titleCloseBtn">
            <button
                onClick={() => {
                setGlobalState('modalOpen',false);
                }}
            >
                X
            </button>
            </div>
            <div className="title">
            <h1>Buy Order</h1>
            </div>
            <div className="createorder-body">
            <p> Buying : {props.nftDetails.nftID.name}</p>
            <p> Price : {metaUtils.convertFromWei(props.nftDetails.price)}</p>
            </div>
            <div className="footer">
            <button
                onClick={() => {
                setGlobalState('modalOpen',false);
                }}
                id="cancelBtn"
            >
                Cancel
            </button>
            <button
                onClick={() => {
                handleExecuteOrder(props.nftDetails);
                }}
            >
                Confirm
            </button>
            
            </div>
        </div>
    </LoadingOverlay>}
    
    {result.status === "failed" && 
        <MessageModal title={result.title} message={result.message}></MessageModal>
    }
    {result.status === "success" && 
        <>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                <button
                    onClick={() => {
                    setGlobalState('modalOpen',false);
                    }}
                >
                    X
                </button>
                </div>
                <div className="title">
                    <h1>{result.title}</h1>
                </div>
                <div className="body">
                    <a className='txn-link' target="_blank" rel="noopener noreferrer" href={"https://polygonscan.com/tx/" + result.message}>
                        {result.message}
                    </a>
                </div>
            </div>
        </>
    }

    </>
  )
}

