import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Primarymarket from './components/pages/Primarymarket';
import Secondarymarket from './components/pages/Secondarymarket';
import Partners from './components/pages/Partners';
import Clans from './components/pages/Clans';
import Highscores from './components/pages/Highscores';
// import { Whitepaper } from './components/pages/Whitepaper';
import { BottomBar } from './components/BottomBar';
import { useGlobalState } from './state';
import Modal from './components/Modals/Modal';
import { useEffect } from 'react';
import { ManageClan } from './components/pages/ManageClan';
import { Referral } from './components/pages/Referral';
function App() {

  const [modalOpen, setModalOpen] = useGlobalState('modalOpen');
  useEffect(()=>{
    if(!modalOpen){
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "block";
      })
    }
  },[modalOpen])
  return (
      <><div className='root-main'>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/primarymarket' component={Primarymarket} />
          <Route path='/secondarymarket' component={Secondarymarket} />
          <Route path='/whitepaper' component={() => {
              window.location.href = 'https://www.wildernessp2e.com/whitepaper.pdf';
              return null;
          }}/>
          {/* <Route path='/whitepaper' component={Whitepaper} /> */}
          <Route path='/partners' component={Partners} />
          <Route path='/highscores' component={Highscores} />
          <Route path='/clans' component={Clans} />
          <Route path='/manageclan' component={ManageClan} />
          <Route path='/referral/:id' component={Referral} />
          <Route path='/referral' component={Referral} />
        </Switch>
        <BottomBar />
      </Router>
    </div><div>
        {modalOpen && <Modal setOpenModal={setModalOpen} />}
      </div></>
  );
}

export default App;
