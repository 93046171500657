import React from 'react'
import { useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useGlobalState } from '../../state'
import metaUtils  from '../../utils/connect'
import { DefaultModal } from './DefaultModal'
import { MessageModal } from './MessageModal'

export const ChangeClanTypeModal = (props) => {
    const changeType = async ()=>{
        console.log("changeType")
        setModalText("Validating")
        await verify().then(async (result)=>{
            setModalText("Requesting")
            await fetch("https://www.wildernessp2e.com:5001/clans?requestType=changetype&clanid="+props.clanData.tokenid+"&userid=" + userDetails[0]+ "&newtype=" + (props.clanData.type==="private"? "public":"private") + "&signature=" +result)
            .then((response) => response.json())
            .then(async (result) => {
                setResult(result)
                setRefresh(!refresh)
            }) 
        })
    }

    const [result, setResult] = useState({'status':"none"})
    const [userDetails] = useGlobalState('userDetails')
    const [refresh, setRefresh] = useGlobalState('refresh')
    const [modalText, setModalText] = useState("")

    const verify = async ()=>{return new Promise(async (resolve, reject)=>{
        await fetch("https://www.wildernessp2e.com:5001/getnonce?userid=" + userDetails[0])
        .then((response) => response.json())
        .then(async (result) => {
            console.log(result)
            if(result.status==="success"){
                let signedMsg = "Requesting to Change Clan Type?clanid="+props.clanData.tokenid +"?userid="+userDetails[0]+"?nonce=" + result.nonce
                console.log(signedMsg)

                try{
                    signedMsg = await metaUtils.personalSign(signedMsg, userDetails[0])
                    setModalText("Validated")
                    resolve(signedMsg)
                }catch(err){
                    setResult({"status":"failed", "title":"Fail message","message":err.message})
                    reject()
                }
            }
        })
    })    
}
  return (
    <>
        {result.status === "none"?
        <LoadingOverlay active={modalText.length===0? false:true} spinner text={modalText}>
            <DefaultModal title={"Approve request"} message={"Are you sure you want to change clan type to " + (props.clanData.type==="private"? "public?":"private?")} button={<button onClick={changeType}>Confirm</button>}></DefaultModal>
        </LoadingOverlay>:<MessageModal title={result.title} message={result.message}></MessageModal>}
    </>
  )
}
