import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { setGlobalState, useGlobalState } from '../../state';
import metaUtils  from '../../utils/connect';
import './DefaultModal.css'

export const JoinClanModal = (props) => {
  const [modalOpen] = useGlobalState('modalOpen');
  const [userDetails] = useGlobalState('userDetails');
  const [onStep, setOnStep] = useState(1);
  const [failMessage, setFailMessage] = useState("");


  useEffect(()=>{
    const requestJoin = async (signature)=>{
        await fetch("https://www.wildernessp2e.com:5001/joinclan?requestType=request_join&clanid="+props.clanID+"&userid=" + userDetails[0]+ "&signature=" +signature) 
            .then((response) => response.json())
            .then(async (result) => {
                if(result.status === "success"){
                    setOnStep(4)
                    setGlobalState('modalOpen',false);
                    setGlobalState('myClanData',result.clan)
                }else{
                    setFailMessage(result.status)
                }
            })
      }

    const verify = async ()=>{
        await fetch("https://www.wildernessp2e.com:5001/getnonce?userid=" + userDetails[0])
        .then((response) => response.json())
        .then(async (result) => {
            console.log(result)
            if(result.status==="success"){
                let signedMsg = "Requesting to Join Clan?clanid="+props.clanID +"?userid="+userDetails[0]+"?nonce=" + result.nonce
                console.log(signedMsg)
                try{
                    signedMsg = await metaUtils.personalSign(signedMsg, userDetails[0])
                    setOnStep(3)
                    await requestJoin(signedMsg)
                }catch(err){
                    setFailMessage(err.message)
                }
            }
        })
    }

    (async () => {
        if(modalOpen){
            // console.log("http://localhost:5000/joinclan?requestType=validating&clanID="+props.clanID+"&userID=" + userDetails[0])
            console.log(props.clanID)
            await fetch("https://www.wildernessp2e.com:5001/joinclan?requestType=validating&clanid="+props.clanID+"&userid=" + userDetails[0])
            .then((response) => response.json())
            .then(async (result) => {
                console.log(result)
                if(result.status === "success"){
                    setOnStep(2)
                    await verify();
                }else{
                    setFailMessage(result.status)
                }
            })
            .catch((error) => console.log(error));
        }
    })();
    
  },[modalOpen,props.clanID,userDetails])
  return (
    <div className="modalContainer">
        <div className="titleCloseBtn">
            <button
                onClick={() => {
                    setGlobalState('modalOpen',false);
                }}
            >
                X
            </button>
        </div>
        
            {failMessage==="" && <>
            <div className='clan-join-status'>
                <div>
                    <LoadingOverlay className='connect-loading' active={onStep>1? false:true} spinner>
                        <button>{onStep>1? "Validated":"Validating"}</button>
                    </LoadingOverlay>
                </div>
                <div>
                    <LoadingOverlay className='connect-loading' active={onStep>2? false:true} spinner>
                        <button>{onStep>2? "Verified":"Verifying"}</button>
                    </LoadingOverlay>
                </div>
                <div>
                    <LoadingOverlay className='connect-loading' active={onStep>3? false:true} spinner>
                        <button>{onStep>3? "Joined":"Joining"}</button>
                    </LoadingOverlay>      
                </div>
            </div>
            </>}
            {failMessage!=="" &&  
            <>
            <div className="title">
                <h1>{"Fail Message:"}</h1>
              </div>
              <div className="body">
                <p>{failMessage}</p>
              </div>
            </>
            }
            
        
  </div>
  )
}
