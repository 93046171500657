import React from 'react'
import { Link } from 'react-router-dom';
import logo from './../images/logos/logo.webp';
import './BottomBar.css';

export const BottomBar = () => {
  return (
    <div className='bottom-bar'>
        <div className='wldy-logo'>
            <img className='bottom-bar-logo' src={logo} alt='WLDY LOGO'></img>
            <h1 className='bottombar-text'>Wilderness P2E</h1>
        </div>
        <div className='bottom-bar-content'>
            <div>
                <div><Link to='/'>Home</Link></div>
                <div><Link to='/primarymarket'>Primary Market</Link></div>
                <div><Link to='/secondarymarket'>Secondary Market</Link></div>
                <div><Link to='/whitepaper'>Whitepaper</Link></div>
                <div><Link to='/partners'>Partners</Link></div>
                <div><Link to='/highscores'>Highscores</Link></div>
                <div><Link to='/clans'>Clans</Link></div>
            </div>
            <div>
                <div><Link target="_blank" to={{pathname:"https://twitter.com/wildernessp2e"}}>Twitter</Link></div>
                <div><Link target="_blank" to={{pathname:"https://www.instagram.com/wildernessp2e/"}}>Instagram</Link></div>
                <div><Link target="_blank" to={{pathname:"https://decentraland.org/"}}>Decentraland</Link></div>
                <div><Link target="_blank" to={{pathname:"https://discord.com/invite/y4GxdEPz4x"}}>Discord</Link></div>
                <div><Link target="_blank" to={{pathname:"https://www.linkedin.com/company/wilderness-p2e/"}}>Linkdin</Link></div>
            </div>

        </div>
    </div>
  )
}
