import React from 'react'
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { setGlobalState, useGlobalState } from '../../state';
import metaUtils  from '../../utils/connect';
import './DefaultModal.css'
import { MessageModal } from './MessageModal';
export const CancelOrderModal = (props) => {
  const [modalText, setModalText] = useState("");
  const [result, setResult] = useState({'status':"none"});
  const [userDetails] = useGlobalState('userDetails');
  const handleCancelOrder = async (nftDetails)=>{
    setModalText("Canceling order on behalf of you");
    console.log(nftDetails)
    const cancleOrderSig = await metaUtils.cancelOrder(nftDetails.nftAddress, nftDetails.tokenId);
    if(cancleOrderSig.status === "success"){
      // fetch("http://localhost:5000/metatxn?requestType=metacancelorder"
      fetch("https://www.wildernessp2e.com:5001/metatxn?requestType=metacancelorder"
      + "&userAddress=" + userDetails[0]
      + "&fSig=" +  cancleOrderSig.fSig
      + "&sigR=" + cancleOrderSig.signature[0]
      + "&sigS=" + cancleOrderSig.signature[1]
      + "&sigV=" + cancleOrderSig.signature[2]
      ).then((response) => response.json())
      .then((result)=>{
          console.log(result)
          if(result.status === "success"){
              setResult(result)
          }else{
              setResult(result)
          }
          
      })
  }else{
      setResult(cancleOrderSig)
  }

  }

    
  return (<>
      {result.status === "none" && 
        <LoadingOverlay active={modalText.length>0? true:false} spinner text={modalText}>
        <div className="modalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setGlobalState('modalOpen',false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h1>Cancel Order</h1>
            </div>
            <div className="body">
              <p>Please confirm to cancel the order</p>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setGlobalState('modalOpen',false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button
                    onClick={() => {
                    handleCancelOrder(props.nftDetails);
                    }}
                >
                    Confirm
                </button>
            </div>
          </div>
      </LoadingOverlay>
      }
      {result.status === "failed" && 
        <MessageModal title={result.title} message={result.message}></MessageModal>
    }
    {result.status === "success" && 
        <>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                <button
                    onClick={() => {
                    setGlobalState('modalOpen',false);
                    }}
                >
                    X
                </button>
                </div>
                <div className="title">
                    <h1>{result.title}</h1>
                </div>
                <div className="body">
                    <a className='txn-link' target="_blank" rel="noopener noreferrer" href={"https://polygonscan.com/tx/" + result.message}>
                        {result.message}
                    </a>
                </div>
            </div>
        </>
    }
  </>

    
  )
}
