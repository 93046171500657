import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import logo from './../images/logos/logo.webp';
import { ConnectButton } from './ConnectButton';
import {useGlobalState} from './../state';
import {MenuItems} from './MenuItems'
function Navbar() {
  const [useConnect] = useGlobalState('useConnect');
  const [userDetails] = useGlobalState('userDetails');
  const [connectButtonStatus] = useGlobalState('connectButtonStatus');
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState("");
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const openWhitepaper = () =>{
    window.open('https://www.wildernessp2e.com/whitepaper.pdf');
    setClick(false);
  };

  const onMouseEnter = (identifier) => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown(identifier);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown("");
    }
  };

  return (
    <>
      <nav className='navbar'>
        <img className='navbar-logo' src={logo} alt='WLDY LOGO'></img>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={()=> onMouseEnter("market")}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to='/primarymarket'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Marketplace <i className='fas fa-caret-down' />
            </Link>
            {dropdown==="market" && <Dropdown menuitems={MenuItems.marketplace}/>}
          </li>
          <li id='nav-item' className='nav-links' onClick={openWhitepaper}>
              Whitepaper          
          </li>
          <li className='nav-item'>
            <Link
              to='/partners'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Partners
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/highscores'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Highscores
            </Link>
          </li>
          <li className='nav-item'
              onMouseEnter={()=> onMouseEnter("clans")}
              onMouseLeave={onMouseLeave}
          >
            <Link
              to='/clans'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Clans <i className='fas fa-caret-down' />
            </Link>
            {dropdown==="clans" && <Dropdown menuitems={MenuItems.clan}/>}
          </li>
          {/* <li className='nav-item'>
            <Link
              to='/referral'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Referral
            </Link>
          </li> */}
        </ul>
        {useConnect? <ConnectButton></ConnectButton>:<Button path="https://play.decentraland.org/?position=-25%2C90" title="PLAY NOW"/>}
      </nav>
      {userDetails.length>0 && useConnect && connectButtonStatus==="Connected" && <div className="account-info">
        <>
        <div className='account-info-header'>Connected Account</div>
        <div>Account: {userDetails[0]}</div>
        {/* <div className='chain-id'>Chain: {userDetails[1]}</div> */}
        </>
      </div> }

    </>
  );
}

export default Navbar;
