var apollo = require('@apollo/client')


class Graph {
    constructor(){
        this.MarketAPIURL = 'https://api.studio.thegraph.com/query/27432/wilderness/version/latest';
    }

    async getPrimaryMarketListing() {
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        const tokensQuery = 
        `
            query {
                items(orderBy: priority, orderDirection: desc) {
                    name
                    upgrade_info {
                      from_address
                      from_item
                    }
                    contract_address
                    item_id
                    price
                    rarity
                    category
                    image
                    priority
                    item_left
                }
            }
        `
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        return data.data
    }

    async getVIPSaleListing() {
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        const tokensQuery = 
        `
            query {
                vipitems(
                    orderBy: priority, orderDirection: desc
                    where: {saleStartTime_lt: `+ Math.round(Date.now()/1000) +`, saleStartTime_gt: `+(Math.round(Date.now()/1000) - 86400)+` }
                    ) {
                    name
                    upgrade_info {
                      from_address
                      from_item
                    }
                    contract_address
                    item_id
                    price
                    rarity
                    category
                    image
                    priority
                    item_left
                    saleStartTime
                }
            }
        `
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        return data.data
    }

    async getUserInventory(userAddress, pageNumber) {
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        console.log("<------------here---------->")
        console.log((parseInt(pageNumber)*50 - 50).toString())
        const tokensQuery = 
        `
            query {
                nfts(
                    where: {currentOwner: "`+ userAddress +`"}
                    skip: `+ (parseInt(pageNumber)*50 - 50).toString() +`
                    first: 50
                  ) {
                    name
                    nftAddress
                    rarity
                    image
                    itemId
                    tokenId
                    category
                    for_sale {
                      nftAddress
                      assetId
                    }
                    issuedId
                  }
            }
        `
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        return data.data
    }

    async getActiveOrder(pageNumber) {
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        const tokensQuery = 
        `
            query {
                orders(where: {status: "active"}
                skip: `+ (parseInt(pageNumber)*50 - 50).toString() +`
                first: 50
                ) {
                    price
                    nftAddress
                    seller
                    time
                    assetId
                    nftID {
                      category
                      image
                      rarity
                      name
                    }
                }
            }
        `
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        return data.data
    }

    async getTokenIDSOfAccount(account, nft, item) {
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        const tokensQuery = 
        `
            query {
                nfts(where: {currentOwner: "`+ account +`", nftAddress: "`+ nft +`", itemId: "`+ item +`"}) {
                    name
                    tokenId
                }
            }
        `
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        return data.data
    }

    async getNFTName(nft,item){
        const graphClient = new apollo.ApolloClient({
            uri: this.MarketAPIURL,
            cache: new apollo.InMemoryCache(),
        })
        const tokensQuery = 
        `
            query {
                nfts(
                    where: {nftAddress: "`+ nft +`", itemId: "`+ item +`"}
                    first: 1
                  ) 
                {
                    name
                }
            }
        `
        console.log(tokensQuery)
        const data = await graphClient.query({
            query: apollo.gql(tokensQuery)
        })
        console.log(data.data)
        return data.data.nfts[0].name
    }

}
let graph_util = new Graph();
// db_utils.addClanTesting()
// module.exports = graph_util
export default graph_util;
