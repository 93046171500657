import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import { setGlobalState } from '../../state';
import { PartnersItems } from '../PartnersItems';
import './Partners.css';

export default function Partners() {
  useEffect(()=>{
    setGlobalState("useConnect", false);
  },[]);
  const content = [];
  for (let i = 0; i < PartnersItems.length; i++) {
    const category = PartnersItems[i].category;
    // if(category === 'Team'){
    //   const teamMembers = []
    //   for(let j=0; j < PartnersItems[i].list.length; j++){
    //     const links= [];
    //     for(let k=0; k<PartnersItems[i].list[j].contacts.length;k++){
    //       if(PartnersItems[i].list[j].contacts[k].type === 'linkdin'){
    //         links.push(
    //           <Link key={PartnersItems[i].list[j].name + 'linkdin'} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
    //             <img src={require("./../../images/partners_dp/socials/linkedin.webp")} alt="social-media"></img>
    //           </Link>
    //         )
    //       }
    //       if(PartnersItems[i].list[j].contacts[k].type === 'twitter'){
    //         links.push(
    //           <Link target="_blank" key={PartnersItems[i].list[j].name + 'twitter'} to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
    //             <img src={require("./../../images/partners_dp/socials/twitter.webp")} alt="social-media"></img>
    //           </Link>
    //         )
    //       }
    //       if(PartnersItems[i].list[j].contacts[k].type === 'twitch'){
    //         links.push(
    //           <Link target="_blank" key={PartnersItems[i].list[j].name + 'twitch'} to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
    //             <img src={require("./../../images/partners_dp/socials/twitch.webp")} alt="social-media"></img>
    //           </Link>
    //         )
    //       }
    //     }
    //     teamMembers.push(<div key={PartnersItems[i].list[j].name} className='teammember'>
    //       <img src={require("./../../images/partners_dp/" + PartnersItems[i].list[j].dp)} alt="dp"></img>
    //       <h3>{PartnersItems[i].list[j].name}</h3>
    //       <h4>{PartnersItems[i].list[j].roll}</h4>
    //       <div className='links'>{links}</div>
    //     </div>)
    //   }
    //   content.push(<div key={category} className='team'>
    //     <div className='teamcontainer'>
    //       {teamMembers}
    //     </div>
    //   </div>);

    // }
    if(category === 'Partners'){
      content.push(<div key={category+"-divider"} className="divider1"></div>);
      // content.push(<h1>Social Media Partners</h1>);
      const teamMembers = []
      for(let j=0; j < PartnersItems[i].list.length; j++){
        const links= [];
        for(let k=0; k<PartnersItems[i].list[j].contacts.length;k++){
          if(PartnersItems[i].list[j].contacts[k].type === 'linkdin'){
            links.push(
              <Link key={PartnersItems[i].list[j].name + 'linkdin'} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
                <img src={require("./../../images/partners_dp/socials/linkedin.webp")} alt="social-media"></img>
              </Link>
            )
          }
          if(PartnersItems[i].list[j].contacts[k].type === 'twitter'){
            links.push(
              <Link key={PartnersItems[i].list[j].name + 'twitter'} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
                <img src={require("./../../images/partners_dp/socials/twitter.webp")} alt="social-media"></img>
              </Link>
            )
          }
          if(PartnersItems[i].list[j].contacts[k].type === 'twitch'){
            links.push(
              <Link key={PartnersItems[i].list[j].name + 'twitch'} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
                <img src={require("./../../images/partners_dp/socials/twitch.webp")} alt="social-media"></img>
              </Link>
            )
          }
        }
        teamMembers.push(<div key={PartnersItems[i].list[j].name} className='teammember'>
          <img src={require("./../../images/partners_dp/" + PartnersItems[i].list[j].dp)} alt="dp"></img>
          <h4>{PartnersItems[i].list[j].name}</h4>
          <div className='links'>{links}</div>
        </div>)
      }
      content.push(<div key={category} className='team'>
        <div className='teamcontainer'>
          {teamMembers}
        </div>
      </div>);
    }
    if(category === 'Technology and Media'){
      content.push(<div key={category+"-divider"} className="divider1"></div>);
      const teamMembers = []
      for(let j=0; j < PartnersItems[i].list.length; j++){
        const links= [];
        for(let k=0; k<PartnersItems[i].list[j].contacts.length;k++){
          if(PartnersItems[i].list[j].contacts[k].type === 'custom'){
            links.push(
              <Link key={PartnersItems[i].list[j].dp} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
                <img className='techmediaimage' src={require("./../../images/partners_dp/" + PartnersItems[i].list[j].dp)} alt="dp"></img>
              </Link>
            )
            links.push(
              <Link key={PartnersItems[i].list[j].name} target="_blank" to={{pathname:PartnersItems[i].list[j].contacts[k].link}}>
              <h4>{PartnersItems[i].list[j].name}</h4>
              </Link>
            )
            teamMembers.push(<div key={PartnersItems[i].list[j].name} className='teammember'>
              {links}
            </div>)
          }else{
            teamMembers.push(<div key={PartnersItems[i].list[j].name} className='teammember'>
            <img className='techmediaimage' src={require("./../../images/partners_dp/" + PartnersItems[i].list[j].dp)} alt="dp"></img>
            <h4>{PartnersItems[i].list[j].name}</h4>
            </div>)
          }
        }
      }
      content.push(<div key={category} className='team'>
        <div className='teamcontainer'>
          {teamMembers}
        </div>
      </div>);
    }
  }

  return (
    <>{content}</>
  )
}
