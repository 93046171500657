
import React from "react";
import { useGlobalState } from "../../state";
import "./Modal.css";

function Modal() {
  const [currentModal] = useGlobalState('currentModal')
  return (
    <div className="modalBackground">
      {currentModal}
    </div>
  );
}

export default Modal;