import React, { useEffect, useState } from 'react';
import { JoinClanButton } from '../JoinClanButton';
import "./Clans.css";
import LoadingOverlay from 'react-loading-overlay';
import {setGlobalState, useGlobalState} from './../../state';
import { MyClan } from './MyClan';
export default function Clans() {
  const [clanList, setClanList] = useState([]);
  const [clanContent, setClanContent] = useState([]);
  const [myClanData] = useGlobalState('myClanData');
  const [userDetails] = useGlobalState('userDetails')
  useEffect(() => {
    fetch("https://www.wildernessp2e.com:5001/clans")
      .then((response) => response.json())
      .then((result) => setClanList(result))
      .catch((error) => console.log(error));
    
    setGlobalState("useConnect", true);
  },[]);

  useEffect(() => {
    const content = []
    for(let i=0; i <clanList.length; i++){
      content.push(
        <div key={clanList[i].tokenid} className='listing'>
          <div>
            <h2>Clan ID: 1_{clanList[i].tokenid}</h2>
            <h2>Owner: {clanList[i].ownername}</h2>
            <h2>Clan Size: {clanList[i].members.length}</h2>
            <h2>Commission: {clanList[i].commission}%</h2>
            <JoinClanButton clanID={clanList[i].tokenid}></JoinClanButton>
          </div>
        </div>
      )
    }
    setClanContent(content);
  },[clanList]);

  useEffect(()=>{
    if(userDetails.length > 0){
      fetch("https://www.wildernessp2e.com:5001/clans?requestType=joinedclan&userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if(result.status === "success"){
          setGlobalState('myClanData',[result.clan])
        }
      })
      .catch((error) => console.log(error));
    }
  },[userDetails])



  return (
    <>
      {myClanData.length>0? <MyClan myClanData={myClanData} ></MyClan>:<></>}
      <div className="listingheader">Wilderness P2E Clans</div>
      <div className='loading-overlay'>
        <LoadingOverlay active={clanList.length ===0? true:false} spinner text='Loading Public Clans'>
          <div className='listingcontainer'>
            {clanContent}
          </div>
        </LoadingOverlay>
      </div>
    </>
  );
}
