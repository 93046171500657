import React from 'react'
import { setGlobalState } from '../../state';
import './DefaultModal.css'
export const DefaultModal = (props) => {
  return (
    <div className="modalContainer">
    <div className="titleCloseBtn">
      <button
        onClick={() => {
          setGlobalState('modalOpen',false);
        }}
      >
        X
      </button>
    </div>
    <div className="title">
      <h1>{props.title}</h1>
    </div>
    <div className="body">
      <p>{props.message}</p>
    </div>
    <div className="footer">
      <button
        onClick={() => {
          setGlobalState('modalOpen',false);
        }}
        id="cancelBtn"
      >
        Cancel
      </button>
      {props.button}
    </div>
  </div>
  )
}
