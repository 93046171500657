
import { signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from 'react-router-dom';
import { setGlobalState, useGlobalState } from '../../state';
import { authentication } from '../../utils/firebase-config';
import { MessageModal } from '../Modals/MessageModal';
import  './Referral.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

export const Referral = () => {
    const [userDetails] = useGlobalState('userDetails');
    const { id } = useParams();
    const [referralID, setReferralID] = useState();
    const [text, setText] = useState();
    const [isCopied, setIsCopied] = useState(false);
    const [processStatus, setProcessStatus] = useState({
      twitterConnect:false,
      twitterFollow:false,
      twitterShare:false,
      gamePlayed:false,
      isReward:false
    })
    const [referred,setreferred] = useState()
    const [isBusy, setIsBusy] = useState()
    const handleCopyClick = async () => {
      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  
    useEffect(()=>{

      setGlobalState("useConnect", true);
      if(userDetails[0]){
        setIsBusy(true)
        fetch("https://www.wildernessp2e.com:5001/social?requestType=query&userid=" + userDetails[0])
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          if(result.status === "success"){
            setProcessStatus(result.data.processStatus)
          }
          setIsBusy(false)
        })

      fetch("https://www.wildernessp2e.com:5001/checkreferred?userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setreferred(result.total)
      }).catch(()=>{
        setreferred(0)
      })

        
      }

    },[userDetails, id])
    useEffect(()=>{
      if(processStatus.twitterConnect === true && processStatus.twitterFollow === true && processStatus.twitterShare === true && processStatus.gamePlayed === true && processStatus.isReward === true){

      checkreward2()
      }
    })

    // useEffect(()=>{
    //   const tempProcessStatus = processStatus;
    //   if(tempProcessStatus.twitterConnect === true && tempProcessStatus.twitterFollow === true && tempProcessStatus.twitterShare === true){
    //     setText("https://www.wildernessp2e.com/referral/" + userDetails[0])
    //     setReferralID(userDetails[0])
    //     if(!processStatus.isReward){
    //       document.querySelectorAll(".root-main").forEach(element => {
    //         element.style.display = "none";
    //       })
    //       setGlobalState('currentModal',<MessageModal title={"Status"} message={"Your Reward will be credited within 30 min"}></MessageModal>)
    //       setGlobalState('modalOpen',true)
    //     }
    //   }
    // },[processStatus, userDetails])

    const checkreward = ()=>{
      console.log('checking reward')
      setIsBusy(true)
      const tempProcessStatus = processStatus;
      if(tempProcessStatus.twitterConnect === true && tempProcessStatus.twitterFollow === true && tempProcessStatus.twitterShare === true && tempProcessStatus.gamePlayed === true && tempProcessStatus.isReward === false){
        
      fetch("https://www.wildernessp2e.com:5001/claimjoiningbonus?userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if(result.status === "success"){
          tempProcessStatus.isReward = true
          setProcessStatus(tempProcessStatus)
        }
        console.log(tempProcessStatus)
        if(tempProcessStatus.isReward === false){


          setGlobalState('currentModal',<MessageModal title={"Error"} message={result.status}></MessageModal>)

        console.log('--')
          setGlobalState('modalOpen',true)
        } else {
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<MessageModal title={"Congratulations"} message={"Wilderness Aura is being airdropped to you now!"}></MessageModal>)
          setGlobalState('modalOpen',true)
        }
      })

        
      }
      setIsBusy(false)
    }

    const checkreward2 = ()=>{



      const tempProcessStatus = processStatus;
      if(tempProcessStatus.isReward === true){
        console.log('checking reward2...')

        setText("https://www.wildernessp2e.com/referral/" + userDetails[0])
        setReferralID(userDetails[0])
        if(!processStatus.isReward){
          processStatus.isReward = true
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<MessageModal title={"Congratulations"} message={"Wilderness Aura is being airdropped to you now!"}></MessageModal>)
          setGlobalState('modalOpen',true)
        }
      }
    }
    const signInWithTwitter = ()=>{
        if(userDetails[0]){
          setIsBusy(true)
            fetch("https://www.wildernessp2e.com:5001/social?requestType=query&userid=" + userDetails[0])
            .then((response) => response.json())
            .then((result) => {
                if(result.status === "failed"){
                    document.querySelectorAll(".root-main").forEach(element => {
                        element.style.display = "none";
                    })
                    setGlobalState('currentModal',
                        <LoadingOverlay active={true} spinner text={"Requesting"}>
                            <MessageModal title={"Status"} message={"Connecting your twitter account with your wallet"}></MessageModal>
                        </LoadingOverlay>)
                    setGlobalState('modalOpen',true)
                    const provider = new TwitterAuthProvider();
                    signInWithPopup(authentication, provider).then((result)=>{
                        fetch("https://www.wildernessp2e.com:5001/social?requestType=register"
                        +"&platform=twitter"
                        +"&userid=" + userDetails[0]
                        +"&screen_name=" + result.user.reloadUserInfo.screenName
                        +"&referral=" + id
                        )
                        .then((response) => response.json())
                        .then((result) => {
                            if(result.status === "success"){
                                document.querySelectorAll(".root-main").forEach(element => {
                                    element.style.display = "none";
                                })
                                setGlobalState('currentModal',<MessageModal title={"Status"} message={"You have been succesfully connected with twitter"}></MessageModal>)
                                setGlobalState('modalOpen',true)
                                const tempStatus = {
                                  twitterConnect:true,
                                  twitterFollow:false,
                                  twitterShare:false
                                }
                                tempStatus.twitterConnect = true;
                                setProcessStatus(tempStatus)
                            }else{
                                document.querySelectorAll(".root-main").forEach(element => {
                                    element.style.display = "none";
                                })
                                setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Failed to connect with twitter"}></MessageModal>)
                                setGlobalState('modalOpen',true)
                            }
    
                        })
                      })
                      .catch((err)=>{
                        console.log(err);
                        setGlobalState('modalOpen',false)
                      });
                    
              }else{
                  document.querySelectorAll(".root-main").forEach(element => {
                      element.style.display = "none";
                  })
                  setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Already Connected With Twitter"}></MessageModal>)
                  setGlobalState('modalOpen',true)
              }
              setIsBusy(false)
            })
            .catch((error) => {
              console.log(error)
              setIsBusy(false)
            });
        }else{
            document.querySelectorAll(".root-main").forEach(element => {
                element.style.display = "none";
            })
            setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Please Connect Your Wallet"}></MessageModal>)
            setGlobalState('modalOpen',true)
          }
    }
    const handelTwitterFollow = ()=>{
      setIsBusy(true)
      fetch("https://www.wildernessp2e.com:5001/social?requestType=twitterfollowupdate&userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        const tempStatus = {...processStatus}
        // tempStatus.twitterFollow = true
        // setProcessStatus(tempStatus)
        if(result.status === "success"){
          // const tempStatus = {...processStatus}
          tempStatus.twitterFollow = true
          setProcessStatus(tempStatus)
        }
        if(tempStatus.twitterFollow === false){
          window.open("https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Ewildernessp2e&screen_name=wildernessp2e", "_blank", "width=500, height=350");
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Please follow us on twitter"} callback={handelTwitterFollow}></MessageModal>)
          setGlobalState('modalOpen',true)
        }
        setIsBusy(false)
      })
    }
    const handelTwitterShare2 = ()=>{
      setIsBusy(true)
      fetch("https://www.wildernessp2e.com:5001/social?requestType=twittershareupdate&userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        const tempStatus = {...processStatus}
        // tempStatus.twitterShare = true
        // setProcessStatus(tempStatus)
        if(result.status === "success"){
          // const tempStatus = {...processStatus}
          tempStatus.twitterShare = true
          setProcessStatus(tempStatus)
        }
        setIsBusy(false)
      })
    }

    const handelTwitterShare = ()=>{
      setIsBusy(true)
      fetch("https://www.wildernessp2e.com:5001/social?requestType=twittershareupdate&userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        const tempStatus = {...processStatus}
        // tempStatus.twitterShare = true
        // setProcessStatus(tempStatus)
        if(result.status === "success"){
          // const tempStatus = {...processStatus}
          tempStatus.twitterShare = true
          setProcessStatus(tempStatus)
        }
        if(tempStatus.twitterShare === false){
          window.open("https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.wildernessp2e.com%2Freferral%2F"+ userDetails[0]+"%20&text=Checking%20out%20%40wildernessp2e%20in%20%40decentraland%20%20%20%20Join%20me%20there%20and%20get%20a%20free%20Wilderness%20Aura%20when%20you%20join!")
          document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
          })
          setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Please share us on twitter"} callback={handelTwitterShare2}></MessageModal>)
          setGlobalState('modalOpen',true)

        }
        setIsBusy(false)
      })
    }

    const checkGameJoined = ()=>{

      setIsBusy(true)

      fetch("https://www.wildernessp2e.com:5001/checkgameplayed?userid=" + userDetails[0])
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if(result.status === 'success'){
          const tempStatus = {...processStatus}
          tempStatus.gamePlayed = true
          setProcessStatus(tempStatus)
        } else {

          window.open("https://play.decentraland.org/?position=-25%2C90", "_blank")
        }
      })
      setIsBusy(false)
    }
  return (
    <>
    <div className="referral-container">

    {/* <h1>Win a free Wilderness Aura</h1> */}
    {!referralID && <div className='pagedesccontainer'>

    
    <p className='pagedesc'>Join Wilderness P2E now and get a free Wilderness Aura - a Decentraland Wearable NFT that gives a 1% drop bonus at Wilderness P2E. Connect your wallet and complete the steps to claim your free NFT</p>


        <a style={{marginTop:'50px',textDecoration:'none'}} className='item' href='https://market.decentraland.org/contracts/0x6389292df6ece0b1548bd5f09cd23515de149e62/items/0' rel="noopener noreferrer" target="_blank">
                <img src='https://peer-ap1.decentraland.org/content/contents/QmTGqARKmewzgyZFk8zwunpSQXf2uS1SqvEG8YtJR3NQwV' style={{height:'250px',width:'250px'}}alt=''></img>
                <p class="itemtitle">Wilderness Aura</p>
                <div className='item-details'>
                  <p class="itemcategory">1% Drop Bonus Utility</p>
              <p class="pricetext">500 $WDLY</p>
                </div>

        </a>
    </div>
    }
    <div className='loadcontent-container'>
      <LoadingOverlay active={userDetails[0] && !isBusy? false:true} spinner text={"Please connect your wallet"}>
          {/* {!referralID && userDetails[0] && <div className="twitter-connect"> */}
          {!referralID && userDetails[0] && <div className="twitter-connect">

          <div className={(processStatus.twitterConnect?'':'')+'buttonwrapper_referral'}>
            <h2> Step 1 : </h2>
              <div className={processStatus.twitterConnect? 'register-twitter disabled':'register-twitter'} onClick={signInWithTwitter}>
                {<FontAwesomeIcon icon={faTwitter} size="2x" color='white'/>}
                <hr></hr>
                <p>Connect with Twitter</p>
              </div>
            </div>
            <div className={(processStatus.twitterConnect?'':'hide ')+'buttonwrapper_referral'}>
            <h2> Step 2 : </h2>
              <div className={(processStatus.twitterConnect?'':'hide ')+(processStatus.twitterFollow? 'register-twitter disabled':'register-twitter')} onClick={handelTwitterFollow}>
                {<FontAwesomeIcon icon={faTwitter} size="2x" color='white'/>}
                <hr></hr>
                <p>Follow us on Twitter</p>
              </div>
            </div>

            <div className={(processStatus.twitterFollow?'':'hide ')+'buttonwrapper_referral'}>
            <h2> Step 3 : </h2>
              <div className={(processStatus.twitterConnect?'':'hide ')+(processStatus.twitterShare? 'register-twitter disabled':'register-twitter')} onClick={handelTwitterShare}>
                {<FontAwesomeIcon icon={faTwitter} size="2x" color='white'/>}
                <hr></hr>
                <p>Share us on Twitter</p>
              </div>
            </div>

            <div className={(processStatus.twitterShare?'':'hide ')+'buttonwrapper_referral'}>
            <h2> Step 4 : </h2>

              <div className={(processStatus.gamePlayed? 'register-twitter disabled':'register-twitter')} onClick={checkGameJoined}>
                  <p>Play Now</p>
              </div>
            </div>

            <div className={(processStatus.gamePlayed?'':'hide ')+'buttonwrapper_referral'}>
            <h2> Step 5 : </h2>
              <div className={(processStatus.isReward? 'register-twitter disabled':'register-twitter')} onClick={checkreward}>
                <p>Claim Reward</p>
              </div>
            </div>


          </div>}
          {referralID && <div className="text-container">
            <h1>Refer and Earn</h1>
            <div className="referral-link">
              <a href={text}>{text}</a>

            <button 
              className={isCopied ? 'copied copybtn' : 'copybtn'}
              onClick={handleCopyClick}
            >
              {isCopied ? 'Copied' : 'Copy to clipboard'}
            </button>
            </div>
            <h2>You have referred : {referred} New Users</h2>
            <div className='rewardscontainer'>

                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0xbe1f42725f2176cc274b8c07cc61fd76fa924d25/items/1' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/QmbeJNba3SfayRBnY86nonLSq8vF3tf2JYeWKYjveLvdBG' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Gen 1 Magician's Robe</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +4 Defence, +2 Magic, +1% Drop</p>
                          <p class="pricetext">2,200 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 3 users to win</button>
                        </div>

                </a>



                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0xbe1f42725f2176cc274b8c07cc61fd76fa924d25/items/0' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/QmYEDbp8sqstYtX6bLqLXeEoGePX69GD8Yck6ZfvtByWFe' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Gen 1 Magician's Crown</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +2 Defence, +4 Magic, +1% Drop</p>
                          <p class="pricetext">2,500 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 5 users to win</button>
                        </div>

                </a>


                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0x6525616757a04f94b737803b9a80ba4664243cbb/items/0' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/bafybeidqcqbk6u3ha4nrqmp2r5h6hytzbftvstubdvhya4gfk6ugyplau4' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Mystical Mask</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +1% Drop</p>
                          <p class="pricetext">3,500 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 10 users to win</button>
                        </div>

                </a>



                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0x75899709f39626c2112bd8311ab61cdbbc2cecde/items/0' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/bafkreidj2dal23vdsfauxstaabfvjrhtspf44rqas7z46l2msyopzddnvm' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Shoes of Poseidon - Grunge</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +10% Movement Speed, +10% Agility XP</p>
                          <p class="pricetext">4,000 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 25 users to win</button>
                        </div>

                </a>


                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0x6525616757a04f94b737803b9a80ba4664243cbb/items/1' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/bafybeifjw35fcxxw7ufzrkiae7qialjnzcjujwbcjxxzp3y5pjhag7hxom' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Royal Mystical Mask</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +1% Drop Bonus</p>
                          <p class="pricetext">30,000 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 50 users to win</button>
                        </div>

                </a>


                <a style={{marginTop:'50px',textDecoration:'none',color:'#fff'}} className='item' href='https://market.decentraland.org/contracts/0x627cbce8d20ef5de98c18fb108d0bd7ee0060608/items/1' rel="noopener noreferrer" target="_blank">
                        <img src='https://peer-ap1.decentraland.org/content/contents/bafybeid3k6w66wafrkcvvruvwbdl37pmdodn3hrfnkba77gn6oupv4toc4' style={{height:'300px',width:'300px'}}alt=''></img>
                        <p class="itemtitle">Gen 2 Royal Magician's Crown</p>
                        <div className='item-details'>
                          <p class="itemcategory">Bonuses: +4 Defence, +7 Magic, +2% Drop</p>
                          <p class="pricetext">50,000 $WDLY</p>
                          <button style={{fontWeight:500,fontSize:'1.2em'}} onClick={()=> {}}>Refer 100 users to win</button>
                        </div>

                </a>
            </div>
          </div>
          
          }
      </LoadingOverlay>
        </div>
        </div>
        
    </>
  )
}
