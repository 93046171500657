import React, { useEffect } from 'react';
import { useState } from 'react';
import { setGlobalState } from '../../state';
import ImageSlider from '../ImageSlider';
import { SliderData } from '../SliderData';
import './Home.css';
export default function Home() {
  const [twitterFeed, setTwitterFeed] = useState();
  useEffect(() => {
    const onPageLoad = () => {
      setTwitterFeed(
      <>
        <div className='twitter-feed'>
          <a className="twitter-timeline" data-width="600" data-theme="dark" href="https://twitter.com/wildernessp2e?ref_src=twsrc%5Etfw">Tweets by wildernessp2e</a>
        </div>
      </>);
      const script1 = document.createElement('script');
      script1.src = "https://platform.twitter.com/widgets.js";
      script1.async = true;
      document.body.appendChild(script1);
      return () => {
        document.body.removeChild(script1);
      }
    };
    setGlobalState("useConnect", false);
    window.addEventListener('scroll', onPageLoad);
    return () => window.removeEventListener('scroll', onPageLoad);  
  }, []);
  

  return (
    <>
      <div id="top" className="topbg1">
        <div className="info">
          <div className="topleft">
            <h2>Wilderness P2E</h2>
            <h1>Play to Earn Role Playing (PvE and PvP) Game in Decentraland with NFT &amp; Crypto Economy</h1>
            <a className="button1" target="_blank" rel="noopener noreferrer" href="https://play.decentraland.org/?position=-25%2C90">
            Play Now
            </a>
            <a className="button1" target="_blank" rel="noopener noreferrer" href="https://dex.guru/token/polygon/0x258d8423d9d6fae7bff3e5b7d1aa13348492aa0b">
            Buy $WLDY Coin
            </a>
        <div className="socials">
          <a href="https://twitter.com/wildernessp2e">
          <img className="socialimg" src={require("./../../images/partners_dp/socials/twitter.webp")} alt="social-media"/>
          </a>
          <a href="https://discord.gg/y4GxdEPz4x">
          <img className="socialimg" src={require("./../../images/partners_dp/socials/discord.webp")} alt="social-media"/>
          </a>
          <a href="https://www.instagram.com/wildernessp2e/">
          <img className="socialimg" src={require("./../../images/partners_dp/socials/instagram.webp")} alt="social-media"/>
          </a>
          <a href="https://www.linkedin.com/company/wilderness-p2e/">
          <img className="socialimg" src={require("./../../images/partners_dp/socials/linkedin.webp")} alt="social-media"/>
          </a>
        </div>
        </div>
        <div className="topimgs">
          <img className="wizardimg1 lazyloaded" src={require("./../../images/partners_dp/website_img1.webp")} alt="social-media"/>
        </div>
      </div>
    </div>
    <div className="divider1"></div>
    {/* {nomicsContainer}
    <div className="divider1"></div> */}
    <ImageSlider slides={SliderData} />
    <div className="divider1 divider-slider"></div>
    {twitterFeed}
    </>
  );
}
