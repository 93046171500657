import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

export function Button(props) {
  return (
    <Link target="_blank" to={{ pathname: props.path }}>
      <button className="play-btn">{props.title}</button>
    </Link>
  );
}

