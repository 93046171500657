export const PartnersItems = [
    {
        category: 'Team',
        list: [
            {
                name:'Champ',
                roll:'Game Developer',
                dp:'ryan_dp.webp',
                contacts: [
                    {
                        type:'linkdin',
                        link:"https://www.linkedin.com/in/palryan/"
                    }
                ]
            },
            {
                name:'Neyyyhhaa',
                roll:'Community',
                dp:'neha_dp.webp',
                contacts: [
                    {
                        type:'linkdin',
                        link:"https://www.linkedin.com/in/neha-singh-9b215568/"
                    },
                    {
                        type:'twitter',
                        link:"https://twitter.com/neyyyhaaa"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/neyyyhaaa"
                    }
                ]
            },
            {
                name:'Saranya',
                roll:'Design',
                dp:'saranya_dp.webp',
                contacts: [
                    {
                        type:'linkdin',
                        link:"https://www.linkedin.com/in/saranya-satheesh-b6abba1a8/"
                    },
                    {
                        type:'twitter',
                        link:"https://twitter.com/neyyyhaaa"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/neyyyhaaa"
                    }
                ]
            },
            {
                name:'FiyaSwitch',
                roll:'Community (Discord)',
                dp:'fiya_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/FiyaSwitch"
                    }
                ]
            },
            {
                name:'Jake',
                roll:'Community (Discord)',
                dp:'jake_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"#"
                    }
                ]
            }
        ]
    },
    {
        category: 'Technology and Media',
        list: [
            {
                name:'Decentraland DAO',
                dp:'dcldao.webp',
                contacts:[
                    {
                        type:'custom',
                        link:'ttps://dao.decentraland.org/'
                    }
                ]
            },
            {
                name:'P2E Diary',
                dp:'p2ediarylogo.webp',
                contacts:[
                    {
                        type:'custom',
                        link:'https://playtoearndiary.com'
                    }
                ]
            }
        ]
    },
    {
        category: 'Partners',
        list: [
            {
                name:'@aaveloo',
                dp:'aaveloo_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/Aaveloo_Art"
                    }
                ]
            },            
            {
                name:'@JTV',
                dp:'jtv_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/JTV____"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/j_t_v"
                    }
                ]
            },          
            {
                name:'@yannakis',
                dp:'yannakis_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/yannakis_dcl"
                    }
                ]
            },            
            {
                name:'MrDhingia.eth',
                dp:'mrdhingia_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/MrDhingia"
                    }
                ]
            },            
            {
                name:'@SirDropBearTV',
                dp:'sirdropbear_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/SirDropBearTV"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/sirdropbeartv"
                    }
                ]
            },            
            {
                name:'@lenacubed',
                dp:'lena_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/lenacubed"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/lenacubed"
                    }
                ]
            },            
            {
                name:'Maryana.eth',
                dp:'maryana_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/maryanaDCL"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/heyitsmaryana"
                    }
                ]
            },            
            {
                name:'@houdini1120',
                dp:'houdini_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/Houdini1120"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/houdini1120"
                    }
                ]
            },            
            {
                name:'@injesterr',
                dp:'injesterr_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/InJesterr"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/InJesterr"
                    }
                ]
            },            
            {
                name:'@Theace96',
                dp:'theace96_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/theace96_"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/theace96"
                    }
                ]
            },            
            {
                name:'@trapkingplays',
                dp:'trapking_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/trapkingplays"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/trapkingplays"
                    }
                ]
            },            
            {
                name:'@scrapgonspaz',
                dp:'sgscrap_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/ScrapGonSpaz"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/scrapgonspaz"
                    }
                ]
            },            
            {
                name:'@newjenerationgaming',
                dp:'newjen_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/NewJenerationG"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/newjenerationgaming"
                    }
                ]
            },            
            {
                name:'@Roachkilla1746',
                dp:'roachkilla_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/Roachkilla1746"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/roachkilla1746"
                    }
                ]
            },            
            {
                name:'@tkzimss',
                dp:'tkzimms_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/tkzimms"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/tkzimms"
                    }
                ]
            },            
            {
                name:'@helpimstreaming',
                dp:'helpimstreaming_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/helpimstreaming"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/helpimstreaming"
                    }
                ]
            },            
            {
                name:'@thereal_zero2hero',
                dp:'zero2hero_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/zero2hero_DCL"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/thereal_zero2hero"
                    }
                ]
            },            
            {
                name:'@nnaatteed',
                dp:'nnaatteedd_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/nnaatteedd"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/nnaatteedd"
                    }
                ]
            },            
            {
                name:'@EPICSMASHERttv',
                dp:'epicsmasher_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/EPICSMASHERTTV"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/EPICSMASHERTTV"
                    }
                ]
            },            
            {
                name:'@datguychuck',
                dp:'datguychuck_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/DatGuyChuck"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/datguychuck"
                    }
                ]
            },            
            {
                name:'@itzshowtime__',
                dp:'itzshowtime_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/iTzSHOWTiME___"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/itzshowtime__"
                    }
                ]
            },            
            {
                name:'@bipolar1011',
                dp:'bipolar_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/Bipolar50778340"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/bipolar1011"
                    }
                ]
            },            
            {
                name:'@mingfaichan',
                dp:'ming_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/mingfaichan"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/mingfaichan"
                    }
                ]
            },            
            {
                name:'@LionSoul007',
                dp:'lionsoul_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/juampabliux"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/LionSoul007"
                    }
                ]
            },            
            {
                name:'@mizuyto',
                dp:'mizuyto_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/mizuyto"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/mizuyto"
                    }
                ]
            },            
            {
                name:'@gowthamsiddarthademan',
                dp:'gowtham_dp.webp',
                contacts: [
                    {
                        type:'twitter',
                        link:"https://twitter.com/Deman_GS"
                    },
                    {
                        type:'twitch',
                        link:"https://www.twitch.tv/gowthamsiddarthademan"
                    }
                ]
            }            
            
        ]
    },
];
  