import React from 'react';
import { setGlobalState } from '../state';
import './DropdownButtons.css';
import { ChangeClanTypeModal } from './Modals/ChangeClanTypeModal';
import { KickModal } from './Modals/KickModal';

export const ManageDropdown = (props) => {



    const handleButtonActions = async (fName)=>{
        document.querySelectorAll(".root-main").forEach(element => {
            element.style.display = "none";
        })
        if(fName === "handleTypeChange"){
            setGlobalState('currentModal', <ChangeClanTypeModal clanData={props.clanData}></ChangeClanTypeModal>)
            setGlobalState('modalOpen',true)
        }
        if(fName === "handleKick"){
            console.log("handleKick")
            setGlobalState('currentModal', <KickModal clanData={props.clanData}></KickModal>)
            setGlobalState('modalOpen',true)
        }
    }
    
    return (
      <>
        <ul
          className='dropdownbutton-menu'
        >
          {props.menuitems.map((item, index) => {
            return (
              <li key={index}>
                <button
                  className={item.cName}
                  onClick={() => {handleButtonActions(item.fName)}}
                >
                  {item.title}
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
}
