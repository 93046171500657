import React, { useEffect, useState } from 'react';
import { MarketPlaceSliderData } from './SliderData';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
const MarketSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  let [alternator,setAlternator] = useState(false);
  useEffect(() => {
    setTimeout(
      () =>{
        nextSlide();
        if(alternator){
            setAlternator(false);
        }else{
            setAlternator(true);
        }
      },
      1500
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternator]);


  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='slider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
      {MarketPlaceSliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active market' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={ require("./../images/marketplace/" + slide.image)} alt='travel' className='image' />
            )}
          </div>
        );
      })}
    </section>
  );
};

export default MarketSlider;