export const MenuItems = {
  marketplace:[
    {
      title: 'Primary Market',
      path: '/primarymarket',
      cName: 'dropdown-link'
    },
    {
      title: 'Secondary Market',
      path: '/secondarymarket',
      cName: 'dropdown-link'
    }
  ],
  clan:[
    {
      title: 'Manage Clan',
      path: '/manageclan',
      cName: 'dropdown-link'
    }
  ],
  manageclan:[
    {
      title: 'Change clan type',
      cName: 'dropdown-links',
      fName: 'handleTypeChange'
    },
    {
      title: 'Kick',
      cName: 'dropdown-links',
      fName: 'handleKick'
    }
  ]
};
