import React from "react";
import { useEffect, useState } from "react";
import "./Highscores.css";
import LoadingOverlay from 'react-loading-overlay';
import { setGlobalState } from "../../state";
import { FaSort } from "react-icons/fa";
export default function Highscores() {
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [sortState, setSortState] = useState({
    rank:false,
    name:false,
    cblevel:false,
    totalxp:false,
    magiclevel:false,
    magic_xp:false,
    healthlevel:false,
    health_xp:false,
    defencelevel:false,
    defence_xp:false,
    cookinglevel:false,
    cooking_xp:false,
    agilitylevel:false,
    agility_xp:false,
    potionlevel:false,
    potion_xp:false
  })

  useEffect(() => {
    
    fetch("https://www.wildernessp2e.com:5001/highscore")
    //   .then((response) => response.json())
    //   .then((result) => setUserList(result))
    //   .catch((error) => console.log(error));
    // fetch("http://localhost:5000/highscore")
      .then((response) => response.json())
      .then((result) => {
        for(let i=0; i<result.length; i++){
          result[i].rank = i+1;
        }
        setUserList(result)
      })
      .catch((error) => console.log(error));
      
    setGlobalState("useConnect", false);
  },[]);

  useEffect(() => {
    const currentPageData = [];
    const currentUserList = userList.slice((pageNumber-1)*50, 50 + 50*(pageNumber-1));
    for(let i=0; i <currentUserList.length; i++){
      currentPageData.push(
        <tr>
          <td>{currentUserList[i].rank}</td>
          <td>{currentUserList[i].id}</td>
          <td>{currentUserList[i].name}</td>
          <td>{currentUserList[i].cblevel}</td>
          <td>{currentUserList[i].totalxp}</td>
          {checkedList.includes("Magic") && <td>{currentUserList[i].magiclevel}</td>}
          {checkedList.includes("Magic") && <td>{currentUserList[i].magic_xp}</td>}
          {checkedList.includes("Health") && <td>{currentUserList[i].hplevel}</td>}
          {checkedList.includes("Health") && <td>{currentUserList[i].health_xp}</td>}
          {checkedList.includes("Defence") && <td>{currentUserList[i].defencelevel}</td>}
          {checkedList.includes("Defence") && <td>{currentUserList[i].defence_xp}</td>}
          {checkedList.includes("Cooking") && <td>{currentUserList[i].cookinglevel}</td>}
          {checkedList.includes("Cooking") && <td>{currentUserList[i].cooking_xp}</td>}
          {checkedList.includes("Agility") && <td>{currentUserList[i].agilitylevel}</td>}
          {checkedList.includes("Agility") && <td>{currentUserList[i].agility_xp}</td>}
          {checkedList.includes("Potion") && <td>{currentUserList[i].potionlevel}</td>}
          {checkedList.includes("Potion") && <td>{currentUserList[i].potion_xp}</td>}
        </tr>
      )
    }
    setCurrentPage(currentPageData);
    
  },[userList, pageNumber, checkedList]);



  const handleGoToPage = ()=>{
    setPageNumber(inputPageNumber);
  }

  const handleInputPage = (event)=>{
    setInputPageNumber(parseInt(event.target.value));
  }

  const handlePrevious = ()=>{
    if(pageNumber - 1 !==0){
      setPageNumber(pageNumber - 1);
      setInputPageNumber(pageNumber - 1);
    }
  }

  const handleNext = ()=>{
    if(pageNumber + 1 !==0){
      setPageNumber(pageNumber + 1);
      setInputPageNumber(pageNumber + 1);
    }
  }

  const handleCheckbox = (category)=>{
    let tempList = [...checkedList];
    if(tempList.includes(category)){
      tempList.splice(checkedList.indexOf(category), 1)
    }else{
      tempList.push(category)
    }
    setCheckedList(tempList);
  }

  const handleSortBy = (value, sort_state)=>{
    let tempList = [...userList]
    let tempSortState = {...sortState}
    if(value === "Rank"){
      tempList.sort((a, b)=>{
        var keyA = a.rank,
        keyB = b.rank
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.rank = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Username"){
      tempList.sort((a, b)=>{
        var keyA = a.name,
        keyB = b.name
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.name = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Combatlevel"){
      tempList.sort((a, b)=>{
        var keyA = a.cblevel,
        keyB = b.cblevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.cblevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Totalxp"){
      tempList.sort((a, b)=>{
        var keyA = a.totalxp,
        keyB = b.totalxp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.totalxp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Defencelevel"){
      tempList.sort((a, b)=>{
        var keyA = a.defencelevel,
        keyB = b.defencelevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.defencelevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Defencexp"){
      tempList.sort((a, b)=>{
        var keyA = a.defence_xp,
        keyB = b.defence_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.defence_xp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Magiclevel"){
      tempList.sort((a, b)=>{
        var keyA = a.magiclevel,
        keyB = b.magiclevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.magiclevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Magicxp"){
      tempList.sort((a, b)=>{
        var keyA = a.magic_xp,
        keyB = b.magic_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.magic_xp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Healthlevel"){
      tempList.sort((a, b)=>{
        var keyA = a.hplevel,
        keyB = b.hplevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.healthlevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Healthxp"){
      tempList.sort((a, b)=>{
        var keyA = a.health_xp,
        keyB = b.health_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.health_xp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Cookinglevel"){
      tempList.sort((a, b)=>{
        var keyA = a.cookinglevel,
        keyB = b.cookinglevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.cookinglevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Cookingxp"){
      tempList.sort((a, b)=>{
        var keyA = a.cooking_xp,
        keyB = b.cooking_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.cooking_xp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Potionlevel"){
      tempList.sort((a, b)=>{
        var keyA = a.potionlevel,
        keyB = b.potionlevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.potionlevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Potionxp"){
      tempList.sort((a, b)=>{
        var keyA = a.potion_xp,
        keyB = b.potion_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.potion_xp = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Agilitylevel"){
      tempList.sort((a, b)=>{
        var keyA = a.agilitylevel,
        keyB = b.agilitylevel
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.agilitylevel = !sort_state;
      setSortState(tempSortState)
    }
    if(value === "Agilityxp"){
      tempList.sort((a, b)=>{
        var keyA = a.agility_xp,
        keyB = b.agility_xp
        if(!sort_state){
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }else{
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
        }
        return 0;
      })
      tempSortState.agility_xp = !sort_state;
      setSortState(tempSortState)
    }
    setUserList(tempList);
  }

  return (
    <>
      <div className="highscore-title">Wilderness P2E Highscores</div>
      <div className="highscoreres-table">
          <div className="highscore-filter">
            <div className="category">
              <h4>Category: </h4>
              <div className="category-options">
                <div>
                  <input type="checkbox"  onChange={()=>handleCheckbox("Defence")} /> Defence
                </div>
                <div>
                  <input type="checkbox" onChange={()=>handleCheckbox("Magic")}/> Magic
                </div>
                <div>
                  <input type="checkbox" onChange={()=>handleCheckbox("Health")}/> Health
                </div>
                <div>
                  <input type="checkbox" onChange={()=>handleCheckbox("Cooking")}/> Cooking
                </div>
                <div>
                  <input type="checkbox" onChange={()=>handleCheckbox("Agility")}/> Agility
                </div>
                <div>
                  <input type="checkbox" onChange={()=>handleCheckbox("Potion")}/> Potion
                </div>
              </div>
            </div>    
          </div>
        <div className="highscore-component">
          <LoadingOverlay active={userList.length ===0? true:false} spinner text='Loading Highscores'>
            <table>
                <thead>
                  <tr>
                    <th onClick={()=>{handleSortBy("Rank", sortState.rank)}}>
                      Rank
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                    </th>
                    <th className="no-sort">
                      Wallet Address
                    </th>
                    <th onClick={()=>{handleSortBy("Username", sortState.name)}}>
                      Username
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                    </th>
                    <th onClick={()=>{handleSortBy("Combatlevel", sortState.cblevel)}}>
                      Combat Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                    </th>
                    <th onClick={()=>{handleSortBy("Totalxp", sortState.totalxp)}}>
                      Total XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                    </th>
                    {checkedList.includes("Magic") && <th onClick={()=>{handleSortBy("Magiclevel", sortState.magiclevel)}}>
                      Magic Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Magic") && <th onClick={()=>{handleSortBy("Magicxp", sortState.magic_xp)}}>
                      Magic XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Health") && <th onClick={()=>{handleSortBy("Healthlevel", sortState.healthlevel)}}>
                      Health Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Health") && <th onClick={()=>{handleSortBy("Healthxp", sortState.health_xp)}}>
                      Health XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Defence") && <th onClick={()=>{handleSortBy("Defencelevel", sortState.defencelevel)}}>
                      Defence Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Defence") && <th onClick={()=>{handleSortBy("Defencexp", sortState.defence_xp)}}>
                      Defence XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Cooking") && <th onClick={()=>{handleSortBy("Cookinglevel", sortState.cookinglevel)}}>
                      Cooking Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Cooking") && <th onClick={()=>{handleSortBy("Cookingxp", sortState.cooking_xp)}}>
                      Cooking XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Agility") && <th onClick={()=>{handleSortBy("Agilitylevel", sortState.agilitylevel)}}>
                      Agility Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Agility") && <th onClick={()=>{handleSortBy("Agilityxp", sortState.agility_xp)}}>
                      Agility XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Potion") && <th onClick={()=>{handleSortBy("Potionlevel", sortState.potionlevel)}}>
                      Potion Level
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                    {checkedList.includes("Potion") && <th onClick={()=>{handleSortBy("Potionxp", sortState.potion_xp)}}>
                      Potion XP
                      <FaSort viewBox="0 -80 320 512"></FaSort>
                      </th>}
                  </tr>
                </thead>
                <tbody>
                  {currentPage}
                </tbody>
              </table>
          </LoadingOverlay>
        </div>
        <div className="score-bottom-panel">
          <button className="previous-button" onClick={handlePrevious}>Previous</button>
          <input className="input-page-number" value={inputPageNumber} onChange={handleInputPage}></input>
          <button className="go-to-page-button" onClick={handleGoToPage}>Go to page</button>
          <button className="next-button" onClick={handleNext}>Next</button>
        </div>
      </div>
    </>
  );
}
