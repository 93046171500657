import React from 'react'
import { useState } from 'react';
import { setGlobalState, useGlobalState } from '../../state';
import './DefaultModal.css'
import './CreateOrderModal.css'
import LoadingOverlay from 'react-loading-overlay';
import { MessageModal } from './MessageModal';
import metaUtils  from '../../utils/connect';

export const CreateOrderModal = (props) => {
    const [result, setResult] = useState({'status':"none"});
    const [timestamp] = useState(new Date(Date.now()+2592000000))
    const [price, setPrice] = useState("");
    const [modalText, setModalText] = useState("");
    const [userDetails] = useGlobalState('userDetails');
    const validatePrice = (value)=> {
        var textVal = value;
        var regex = /^[1-9]\d*(\.\d+)?$/;
        var passed = textVal.match(regex);
        if (passed == null) {
            alert("Enter price only.");
            return false
        }
        return true
    }
    function handleChange(e)  {
        if(e.target.value){
            if(validatePrice(e.target.value)){
                e.preventDefault();
                setPrice(e.target.value);
            }else{
                setPrice("");
            }
        }else{
            setPrice("");
        }

    }
    const handleCreateOrder = async (nftDetails)=>{
        if(validatePrice(price)){
            setModalText("Requesting your nft to be used on behalf of you");
            console.log(nftDetails)
            const nftSig = await metaUtils.metaApproveNFT(nftDetails.nftAddress, nftDetails.tokenId);
            if(nftSig.status === "success"){
                // fetch("http://localhost:5000/metatxn?requestType=metacreateorder"
                fetch("https://www.wildernessp2e.com:5001/metatxn?requestType=metacreateorder"
                + "&nft=" + nftDetails.nftAddress
                + "&tokenid=" + nftDetails.tokenId
                + "&userAddress=" + userDetails[0]
                + "&price=" + metaUtils.convertToWei(price) 
                + "&expireAt=" + timestamp.getTime()
                + "&nft_sigR=" + nftSig.signature[0]
                + "&nft_sigS=" + nftSig.signature[1]
                + "&nft_sigV=" + nftSig.signature[2]
                ).then((response) => response.json())
                .then((result)=>{
                    console.log(result)
                    if(result.status === "success"){
                        setResult(result)
                    }else{
                        setResult(result)
                    }
                    
                })
            }else{
                setResult(nftSig)
            }

        }else{
            setResult({
                'status':'failed',
                'title': 'Aleart',
                'message': 'Enter valid price'
            })
        }
    }   
  return (<>
    {result.status === "none" && 
        <LoadingOverlay active={modalText.length>0? true:false} spinner text={modalText}>
        <div className="modalContainer">
            <div className="titleCloseBtn">
            <button
                onClick={() => {
                setGlobalState('modalOpen',false);
                }}
            >
                X
            </button>
            </div>
            <div className="title">
            <h1>Create Order</h1>
            </div>
            <div className="createorder-body">
            <p> Selling : {props.nftDetails.name}</p>
            <p>Expire on :{timestamp.toUTCString()}</p>
            <div className='order-price'>Price: 
                <input
                type='text'
                value={price}
                onChange={handleChange}
                placeholder = "Enter the price"
                />
                <label> WLDY</label>
                </div>
            </div>
            <div className="footer">
            <button
                onClick={() => {
                setGlobalState('modalOpen',false);
                }}
                id="cancelBtn"
            >
                Cancel
            </button>
            <button
                onClick={() => {
                handleCreateOrder(props.nftDetails);
                }}
            >
                Confirm
            </button>
            
            </div>
        </div>
    </LoadingOverlay>}
    
    {result.status === "failed" && 
        <MessageModal title={result.title} message={result.message}></MessageModal>
    }
    {result.status === "success" && 
        <>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                <button
                    onClick={() => {
                    setGlobalState('modalOpen',false);
                    }}
                >
                    X
                </button>
                </div>
                <div className="title">
                    <h1>{result.title}</h1>
                </div>
                <div className="body">
                    <a className='txn-link' target="_blank" rel="noopener noreferrer" href={"https://polygonscan.com/tx/" + result.message}>
                        {result.message}
                    </a>
                </div>
            </div>
        </>
    }

  </>)
}
