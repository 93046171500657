import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { setGlobalState, useGlobalState } from '../../state'
import  metaUtils from '../../utils/connect'
import { DefaultModal } from './DefaultModal'
import { MessageModal } from './MessageModal'
import './BuyModal.css'
import graph_util from '../../utils/graph'

export const BuyModal = (props) => {
  const [result, setResult] = useState({'status':"none"});
  const [modalText, setModalText] = useState("Validating");
  const [userDetails] = useGlobalState('userDetails');
  const [message,setMessage] = useState("");
  const [burnNFTID, setBurnNFTID]= useState(null);
  const buyItem = async (itemDetails, vipSale)=>{
    
    if(burnNFTID){
        setModalText("Approving to burn nft on behalf of you.")
        //Take signature for nft burn and wldy burn and send it to backend to execute txn
        console.log(itemDetails)
        const nftSig = await metaUtils.metaBurnNFT(itemDetails.upgrade_info.from_address, burnNFTID)
        if(nftSig.status === "success"){
            setModalText("Approving to burn wldy on behalf of you.")
            const wldySig = await metaUtils.metaBurnWLDY(itemDetails.price)
            if(wldySig.status === "success"){
                setModalText("Executing Gasless Transaction")
                const reqType = vipSale? "meta_vip_upgrade":"metaupgrade"
                fetch("https://www.wildernessp2e.com:5001/metatxn?requestType=" + reqType
                + "&nft=" + itemDetails.contract_address
                + "&item=" + itemDetails.item_id
                + "&tokenid=" + burnNFTID
                + "&userAddress=" + userDetails[0]
                + "&nft_sigR=" + nftSig.signature[0]
                + "&nft_sigS=" + nftSig.signature[1]
                + "&nft_sigV=" + nftSig.signature[2]
                + "&coin_sigR=" + wldySig.signature[0]
                + "&coin_sigS=" + wldySig.signature[1]
                + "&coin_sigV=" + wldySig.signature[2]
                ).then((response) => response.json())
                .then((result)=>{
                    console.log(result)
                    if(result.status === "success"){
                        setResult(result)
                    }else{
                        setResult(result)
                    }
                    
                })
            }else{
                setResult(wldySig)
            }
        }else{
            setResult(nftSig)
        }
    }else{
        //Take signature for wldy burn and send it to backend to execute txn
        setModalText("Approving to burn wldy on behalf of you.")
        const wldySig = await metaUtils.metaBurnWLDY(itemDetails.price)
        if(wldySig.status === "success"){
            setModalText("Executing Gasless Transaction")
            const reqType = vipSale? "meta_vip_buy":"metabuy";
            fetch("https://www.wildernessp2e.com:5001/metatxn?requestType=" + reqType
            + "&nft=" + itemDetails.contract_address
            + "&item=" + itemDetails.item_id
            + "&userAddress=" + userDetails[0]
            + "&coin_sigR=" + wldySig.signature[0]
            + "&coin_sigS=" + wldySig.signature[1]
            + "&coin_sigV=" + wldySig.signature[2]
            ).then((response) => response.json())
            .then((result)=>{
                console.log(result)
                setResult(result)
            })
        }else{
            setResult(wldySig)
        }
    }
    
  }

  useEffect(()=>{
    if(props.itemDetails.upgrade_info){
        const from_address = props.itemDetails.upgrade_info.from_address;
        const from_item = props.itemDetails.upgrade_info.from_item;
        graph_util.getTokenIDSOfAccount(userDetails[0],from_address,from_item ).then(result=>{
            console.log(result.nfts);
            if(result.nfts.length >0){
                setBurnNFTID(result.nfts[result.nfts.length-1].tokenId)
                setMessage("Are you sure you want to burn your nft and buy? Item: "
                    + props.itemDetails.name 
                    +" Price: " 
                    + metaUtils.convertFromWei(props.itemDetails.price) +" WLDY"
                    + " Burning NFT: " + result.nfts[0].name
                );
                setModalText("")
            }else{
                graph_util.getNFTName(from_address,from_item).then(result=>{
                    setResult({
                        'status':'failed',
                        'title': 'Aleart',
                        'message': 'You must have '+ result + 'to upgrade it from.'
                    })
                })
            }
        })  
        
    }else{
        setModalText("")
        setMessage("Are you sure you want to buy item? Item: " + props.itemDetails.name +" Price: " + metaUtils.convertFromWei(props.itemDetails.price) +" WLDY");
    }
  },[props, userDetails])

  return (
    <>
    {result.status === "none" && 
        <LoadingOverlay active={modalText.length>0? true:false} spinner text={modalText}>
            <DefaultModal title={"Approve request"} message={message} 
                button={<button onClick={()=>buyItem(props.itemDetails, props.vipSale)}>Confirm</button>}>
            </DefaultModal>
        </LoadingOverlay>
    }
    {result.status === "failed" && 
        <MessageModal title={result.title} message={result.message}></MessageModal>
    }
    {result.status === "success" && 
        <>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                <button
                    onClick={() => {
                    setGlobalState('modalOpen',false);
                    }}
                >
                    X
                </button>
                </div>
                <div className="title">
                    <h1>{result.title}</h1>
                </div>
                <div className="body">
                    <a className='txn-link' target="_blank" rel="noopener noreferrer" href={"https://polygonscan.com/tx/" + result.message}>
                        {result.message}
                    </a>
                </div>
            </div>
        </>
    }
    
    </>
  )
}
