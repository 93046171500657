import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import '../../App.css';
import './Secondarymarket.css';
import { setGlobalState, useGlobalState } from '../../state';
import  metaUtils  from '../../utils/connect';
import { MessageModal } from '../Modals/MessageModal';
import { CreateOrderModal } from '..//Modals/CreateOrderModal'
import graph_util from '../../utils/graph';
import { CancelOrderModal } from '../Modals/CancelOrderModal';
import { ExecuteOrderModal } from '../Modals/ExecuteOrderModal';

export default function Secondarymarket() {
  const [currentPage, setCurrentPage] = useState("marketplace");
  const [userDetails] = useGlobalState('userDetails')
  const [items, setItems] = useState([])
  const [title, setTitle] = useState("Secondary Marketplace");
  const [loadingText, setLoadingText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);


  const handlePrevious = ()=>{
    setPageNumber(pageNumber-1)
  }

  const handleNext = ()=>{
    setPageNumber(pageNumber+1)
  }



  useEffect(()=>{
    
  const handleCreateOrder= async (nftDetails)=>{
    if(userDetails.length>0){
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
      })
      setGlobalState('currentModal',<CreateOrderModal nftDetails={nftDetails}></CreateOrderModal>)
      setGlobalState('modalOpen',true)
    }else{
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
      })
      setGlobalState('currentModal',<MessageModal title="Alert" message="Please connect your wallet"></MessageModal>)
      setGlobalState('modalOpen',true)
    }

  }

  const handleCancelOrder= async (nftDetails)=>{
    if(userDetails.length>0){
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
      })
      setGlobalState('currentModal',<CancelOrderModal nftDetails={nftDetails}></CancelOrderModal>)
      setGlobalState('modalOpen',true)
    }else{
      document.querySelectorAll(".root-main").forEach(element => {
        element.style.display = "none";
      })
      setGlobalState('currentModal',<MessageModal title="Alert" message="Please connect your wallet"></MessageModal>)
      setGlobalState('modalOpen',true)
    }

}

const handleExecuteOrder = async (nftDetails)=>{
  if(userDetails.length>0){
    document.querySelectorAll(".root-main").forEach(element => {
      element.style.display = "none";
    })
    setGlobalState('currentModal',<ExecuteOrderModal nftDetails={nftDetails}></ExecuteOrderModal>)
    setGlobalState('modalOpen',true)
  }else{
    document.querySelectorAll(".root-main").forEach(element => {
      element.style.display = "none";
    })
    setGlobalState('currentModal',<MessageModal title="Alert" message="Please connect your wallet"></MessageModal>)
    setGlobalState('modalOpen',true)
  }
}
    setGlobalState("useConnect", true);
    setItems([]);
    const  handleInventory= async ()=>{
      if(userDetails.length>0){
        setTitle("Inventory")
        graph_util.getUserInventory(userDetails[0], pageNumber+1).then((result)=>{
          if(result.nfts.length===0){
            setIsLastPage(true)
          }else{
            setIsLastPage(false)
          }
        })
        graph_util.getUserInventory(userDetails[0], pageNumber).then((result)=>{
          const itemContent = []
          console.log(result)
          for(let i=0;i<result.nfts.length;i++){
            itemContent.push(<>
              <div className='item'>
                <img src={result.nfts[i].image} alt=''></img>
                <p className="itemtitle">{result.nfts[i].name}</p>
                <div className='item-details'>
                  <p className="itemcategory">{" " + result.nfts[i].category.replace('_'," ").toUpperCase()}</p>
                  <p className={result.nfts[i].rarity+" raritytext"}>{" " + result.nfts[i].rarity.toUpperCase()}</p>
                  <p>Item ID: {result.nfts[i].issuedId}</p>
                </div>
                {result.nfts[i].for_sale?<button className='cancle-order' onClick={()=>handleCancelOrder(result.nfts[i])}>Cancel Order</button>:<button onClick={()=>handleCreateOrder(result.nfts[i])}>Sell</button>}
              </div>
            </>)
          }
          setItems(itemContent)
          setLoadingText("")
          console.log(result)
        })
        .catch((error) => console.log(error));
      }else{
        document.querySelectorAll(".root-main").forEach(element => {
          element.style.display = "none";
        })
        setGlobalState('currentModal',<MessageModal title={"Alert"} message={"Please connect your wallet before buying"}></MessageModal>)
        setGlobalState('modalOpen',true)
      }
    }
    const handleMarketplace = ()=>{
      setTitle("Secondary Marketplace")
      graph_util.getActiveOrder(pageNumber+1).then((result)=>{
        console.log('p+1')
        console.log(result)
        if(result.orders.length === 0){
          setIsLastPage(true)
        }else{
          setIsLastPage(false)
        }
      })
      graph_util.getActiveOrder(pageNumber)
        .then((result)=>{
          const itemContent = []
          console.log('p')
          console.log(result)
          for(let i=0;i<result.orders.length;i++){
            itemContent.push(<>
              <div className='item'>
                <img src={result.orders[i].nftID.image} alt=''></img>
                <p className="itemtitle">{result.orders[i].nftID.name}</p>
                <div className='item-details'>
                  <p>{" " + result.orders[i].nftID.category.replace('_'," ").toUpperCase()}</p>
                  <p className={result.orders[i].nftID.rarity+" raritytext"}>{" " + result.orders[i].nftID.rarity.toUpperCase()}</p>
                  <p className="pricetext">{ addCommas("" + metaUtils.convertFromWei(result.orders[i].price))} $WDLY</p>
                </div>
                <button onClick={()=> {handleExecuteOrder(result.orders[i])}}>Buy</button>
              </div>
            </>)
          }
          setItems(itemContent)
          setLoadingText("")
        })
        .catch((error) => console.log(error));
    }
    
    if(currentPage === "marketplace"){
      setLoadingText("Loading Items")
      handleMarketplace();
    }else{
      if(userDetails[0]){
        setLoadingText("Loading Items")
        handleInventory();
      }else{
        setLoadingText("Please Connect you wallet")
      }
    }
  },[userDetails, currentPage, pageNumber])
  return <>
    <div className="highscore-title">{title}</div>
    <div className="menu-container">
      {currentPage === "marketplace" && <button onClick={()=>{setCurrentPage("inventory"); setPageNumber(1);}}> Inventory </button>}
      {currentPage === "inventory" && <button onClick={()=>{setCurrentPage("marketplace"); setPageNumber(1);}}> Marketplace </button>}
    </div>
    <LoadingOverlay active={loadingText.length===0? false:true} spinner text={loadingText}>
      <div className='marketplace-container'>
        {items}
      </div>
    </LoadingOverlay>
    {items.length>0 && <div className="score-bottom-panel1">
          {pageNumber >1 && <button className="previous-button" onClick={handlePrevious}>Previous</button>}
          {!isLastPage && <button className="next-button" onClick={handleNext}>Next</button>}
    </div>}
  </>
}

function addCommas(str) {
  var parts = (str + "").split("."),
      main = parts[0],
      len = main.length,
      output = "",
      first = main.charAt(0),
      i;

  if (first === '-') {
      main = main.slice(1);
      len = main.length;    
  } else {
      first = "";
  }
  i = len - 1;
  while(i >= 0) {
      output = main.charAt(i) + output;
      if ((len - i) % 3 === 0 && i > 0) {
          output = "," + output;
      }
      --i;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
      output += "." + parts[1];
  }
  return output;
}