import React from 'react'
import { useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { setGlobalState, useGlobalState } from '../../state'
import metaUtils  from '../../utils/connect'
import { DefaultModal } from './DefaultModal'
import { MessageModal } from './MessageModal'

export const LeaveClanModal = (props) => {
    const [result, setResult] = useState({'status':"none"})
    const [userDetails] = useGlobalState('userDetails')
    const [modalText, setModalText] = useState("")
    const requestLeave = async(signature)=>{
        setModalText("Requesting")
        await fetch("https://www.wildernessp2e.com:5001/clans?requestType=leaveclan&clanid="+props.clanID+"&userid=" + userDetails[0]+ "&signature=" +signature) 
        .then((response) => response.json())
        .then(async (result) => {
            setGlobalState('myClanData',[])
            setResult(result)
        })
    }
    const verify = async ()=>{
        await fetch("https://www.wildernessp2e.com:5001/getnonce?userid=" + userDetails[0])
        .then((response) => response.json())
        .then(async (result) => {
            console.log(result)
            if(result.status==="success"){
                let signedMsg = "Requesting to Leave Clan?clanid="+props.clanID +"?userid="+userDetails[0]+"?nonce=" + result.nonce
                console.log(signedMsg)

                try{
                    signedMsg = await metaUtils.personalSign(signedMsg, userDetails[0])
                    setModalText("Validated")
                    await requestLeave(signedMsg)
                }catch(err){
                    setResult({"status":"failed", "title":"Fail message","message":err.message})
                }
            }
        })
    }
    const leaveClan = async ()=>{
        setModalText("Validating")
        await verify()

    }
    
  return (
    <>
        {result.status === "none"? 
        <LoadingOverlay active={modalText.length === 0? false:true} spinner text={modalText}>
            <DefaultModal title={"Approve request"} message={"Are you sure you want to leave this clan?"} button={<button onClick={leaveClan}>Confirm</button>}></DefaultModal>
        </LoadingOverlay>:<MessageModal title={result.title} message={result.message}></MessageModal>}
    </>
  )
}
