import { createGlobalState } from "react-hooks-global-state";

const {setGlobalState, useGlobalState} = createGlobalState({
    refresh:false,
    useConnect: false,
    connectButtonStatus: "Connect",
    userDetails: [],
    modalOpen: false,
    currentModal:[],
    myClanData:[]
});

export { useGlobalState, setGlobalState};